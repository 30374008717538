export const collectionOne = [
	{
		"id": 1,
		"image": "/assets/Generation-1/1.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.63
	},
	{
		"id": 2,
		"image": "/assets/Generation-1/2.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 3,
		"image": "/assets/Generation-1/3.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 62.63
	},
	{
		"id": 4,
		"image": "/assets/Generation-1/4.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 66.25
	},
	{
		"id": 5,
		"image": "/assets/Generation-1/5.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.03999999999999
	},
	{
		"id": 6,
		"image": "/assets/Generation-1/6.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.9
	},
	{
		"id": 7,
		"image": "/assets/Generation-1/7.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.72
	},
	{
		"id": 8,
		"image": "/assets/Generation-1/8.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.25
	},
	{
		"id": 9,
		"image": "/assets/Generation-1/9.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 65.75
	},
	{
		"id": 10,
		"image": "/assets/Generation-1/10.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.7
	},
	{
		"id": 11,
		"image": "/assets/Generation-1/11.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 55.45
	},
	{
		"id": 12,
		"image": "/assets/Generation-1/12.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 52.5
	},
	{
		"id": 13,
		"image": "/assets/Generation-1/13.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.72
	},
	{
		"id": 14,
		"image": "/assets/Generation-1/14.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 58.72
	},
	{
		"id": 15,
		"image": "/assets/Generation-1/15.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 76.6
	},
	{
		"id": 16,
		"image": "/assets/Generation-1/16.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.63
	},
	{
		"id": 17,
		"image": "/assets/Generation-1/17.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 18,
		"image": "/assets/Generation-1/18.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.3
	},
	{
		"id": 19,
		"image": "/assets/Generation-1/19.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 72.85
	},
	{
		"id": 20,
		"image": "/assets/Generation-1/20.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Orange", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.27
	},
	{
		"id": 21,
		"image": "/assets/Generation-1/21.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.83000000000001
	},
	{
		"id": 22,
		"image": "/assets/Generation-1/22.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 59.63
	},
	{
		"id": 23,
		"image": "/assets/Generation-1/23.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.68
	},
	{
		"id": 24,
		"image": "/assets/Generation-1/24.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.92
	},
	{
		"id": 25,
		"image": "/assets/Generation-1/25.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 77.80000000000001
	},
	{
		"id": 26,
		"image": "/assets/Generation-1/26.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 27,
		"image": "/assets/Generation-1/27.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.11
	},
	{
		"id": 28,
		"image": "/assets/Generation-1/28.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 70.05
	},
	{
		"id": 29,
		"image": "/assets/Generation-1/29.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 70.80000000000001
	},
	{
		"id": 30,
		"image": "/assets/Generation-1/30.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.36
	},
	{
		"id": 31,
		"image": "/assets/Generation-1/31.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 54.6
	},
	{
		"id": 32,
		"image": "/assets/Generation-1/32.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.6
	},
	{
		"id": 33,
		"image": "/assets/Generation-1/33.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.56
	},
	{
		"id": 34,
		"image": "/assets/Generation-1/34.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 35,
		"image": "/assets/Generation-1/35.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.35
	},
	{
		"id": 36,
		"image": "/assets/Generation-1/36.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 78.7
	},
	{
		"id": 37,
		"image": "/assets/Generation-1/37.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 63.470000000000006
	},
	{
		"id": 38,
		"image": "/assets/Generation-1/38.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 58.16
	},
	{
		"id": 39,
		"image": "/assets/Generation-1/39.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 60.9
	},
	{
		"id": 40,
		"image": "/assets/Generation-1/40.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 66.56
	},
	{
		"id": 41,
		"image": "/assets/Generation-1/41.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 42,
		"image": "/assets/Generation-1/42.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.56
	},
	{
		"id": 43,
		"image": "/assets/Generation-1/43.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 49.9
	},
	{
		"id": 44,
		"image": "/assets/Generation-1/44.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.92
	},
	{
		"id": 45,
		"image": "/assets/Generation-1/45.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.1
	},
	{
		"id": 46,
		"image": "/assets/Generation-1/46.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 59.42
	},
	{
		"id": 47,
		"image": "/assets/Generation-1/47.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 74.7
	},
	{
		"id": 48,
		"image": "/assets/Generation-1/48.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.2
	},
	{
		"id": 49,
		"image": "/assets/Generation-1/49.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 69.83000000000001
	},
	{
		"id": 50,
		"image": "/assets/Generation-1/50.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 51,
		"image": "/assets/Generation-1/51.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 58.800000000000004
	},
	{
		"id": 52,
		"image": "/assets/Generation-1/52.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.59
	},
	{
		"id": 53,
		"image": "/assets/Generation-1/53.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.36
	},
	{
		"id": 54,
		"image": "/assets/Generation-1/54.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 57.95
	},
	{
		"id": 55,
		"image": "/assets/Generation-1/55.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.16
	},
	{
		"id": 56,
		"image": "/assets/Generation-1/56.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 67.55000000000001
	},
	{
		"id": 57,
		"image": "/assets/Generation-1/57.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 62.63
	},
	{
		"id": 58,
		"image": "/assets/Generation-1/58.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.11
	},
	{
		"id": 59,
		"image": "/assets/Generation-1/59.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 68.85
	},
	{
		"id": 60,
		"image": "/assets/Generation-1/60.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 61,
		"image": "/assets/Generation-1/61.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.6
	},
	{
		"id": 62,
		"image": "/assets/Generation-1/62.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.95
	},
	{
		"id": 63,
		"image": "/assets/Generation-1/63.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 59.400000000000006
	},
	{
		"id": 64,
		"image": "/assets/Generation-1/64.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 65.56
	},
	{
		"id": 65,
		"image": "/assets/Generation-1/65.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.65
	},
	{
		"id": 66,
		"image": "/assets/Generation-1/66.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.42
	},
	{
		"id": 67,
		"image": "/assets/Generation-1/67.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.35
	},
	{
		"id": 68,
		"image": "/assets/Generation-1/68.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.6
	},
	{
		"id": 69,
		"image": "/assets/Generation-1/69.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.47999999999999
	},
	{
		"id": 70,
		"image": "/assets/Generation-1/70.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.36
	},
	{
		"id": 71,
		"image": "/assets/Generation-1/71.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.92
	},
	{
		"id": 72,
		"image": "/assets/Generation-1/72.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 68.47999999999999
	},
	{
		"id": 73,
		"image": "/assets/Generation-1/73.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 74,
		"image": "/assets/Generation-1/74.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 56.72
	},
	{
		"id": 75,
		"image": "/assets/Generation-1/75.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 76.56
	},
	{
		"id": 76,
		"image": "/assets/Generation-1/76.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.63
	},
	{
		"id": 77,
		"image": "/assets/Generation-1/77.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.5
	},
	{
		"id": 78,
		"image": "/assets/Generation-1/78.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 62.92
	},
	{
		"id": 79,
		"image": "/assets/Generation-1/79.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 80,
		"image": "/assets/Generation-1/80.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.25
	},
	{
		"id": 81,
		"image": "/assets/Generation-1/81.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 82,
		"image": "/assets/Generation-1/82.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.24000000000001
	},
	{
		"id": 83,
		"image": "/assets/Generation-1/83.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.04
	},
	{
		"id": 84,
		"image": "/assets/Generation-1/84.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 85,
		"image": "/assets/Generation-1/85.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 64.7
	},
	{
		"id": 86,
		"image": "/assets/Generation-1/86.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.80000000000001
	},
	{
		"id": 87,
		"image": "/assets/Generation-1/87.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 62.6
	},
	{
		"id": 88,
		"image": "/assets/Generation-1/88.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.800000000000004
	},
	{
		"id": 89,
		"image": "/assets/Generation-1/89.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 62.65
	},
	{
		"id": 90,
		"image": "/assets/Generation-1/90.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.55000000000001
	},
	{
		"id": 91,
		"image": "/assets/Generation-1/91.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 63.36
	},
	{
		"id": 92,
		"image": "/assets/Generation-1/92.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 71.25
	},
	{
		"id": 93,
		"image": "/assets/Generation-1/93.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.1
	},
	{
		"id": 94,
		"image": "/assets/Generation-1/94.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.92
	},
	{
		"id": 95,
		"image": "/assets/Generation-1/95.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.63
	},
	{
		"id": 96,
		"image": "/assets/Generation-1/96.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.16
	},
	{
		"id": 97,
		"image": "/assets/Generation-1/97.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 57.2
	},
	{
		"id": 98,
		"image": "/assets/Generation-1/98.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 57.5
	},
	{
		"id": 99,
		"image": "/assets/Generation-1/99.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.6
	},
	{
		"id": 100,
		"image": "/assets/Generation-1/100.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.55000000000001
	},
	{
		"id": 101,
		"image": "/assets/Generation-1/101.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.55000000000001
	},
	{
		"id": 102,
		"image": "/assets/Generation-1/102.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 61.72
	},
	{
		"id": 103,
		"image": "/assets/Generation-1/103.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 104,
		"image": "/assets/Generation-1/104.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.2
	},
	{
		"id": 105,
		"image": "/assets/Generation-1/105.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 69.11
	},
	{
		"id": 106,
		"image": "/assets/Generation-1/106.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 68.16
	},
	{
		"id": 107,
		"image": "/assets/Generation-1/107.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 73.36
	},
	{
		"id": 108,
		"image": "/assets/Generation-1/108.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.42
	},
	{
		"id": 109,
		"image": "/assets/Generation-1/109.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 78.4
	},
	{
		"id": 110,
		"image": "/assets/Generation-1/110.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 59.6
	},
	{
		"id": 111,
		"image": "/assets/Generation-1/111.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.21000000000001
	},
	{
		"id": 112,
		"image": "/assets/Generation-1/112.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.7
	},
	{
		"id": 113,
		"image": "/assets/Generation-1/113.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.62
	},
	{
		"id": 114,
		"image": "/assets/Generation-1/114.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 70.24000000000001
	},
	{
		"id": 115,
		"image": "/assets/Generation-1/115.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 67.92
	},
	{
		"id": 116,
		"image": "/assets/Generation-1/116.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 68.80000000000001
	},
	{
		"id": 117,
		"image": "/assets/Generation-1/117.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.16
	},
	{
		"id": 118,
		"image": "/assets/Generation-1/118.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 52.650000000000006
	},
	{
		"id": 119,
		"image": "/assets/Generation-1/119.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 120,
		"image": "/assets/Generation-1/120.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.9
	},
	{
		"id": 121,
		"image": "/assets/Generation-1/121.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.830000000000005
	},
	{
		"id": 122,
		"image": "/assets/Generation-1/122.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 56.7
	},
	{
		"id": 123,
		"image": "/assets/Generation-1/123.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.9
	},
	{
		"id": 124,
		"image": "/assets/Generation-1/124.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.4
	},
	{
		"id": 125,
		"image": "/assets/Generation-1/125.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 60.6
	},
	{
		"id": 126,
		"image": "/assets/Generation-1/126.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.41
	},
	{
		"id": 127,
		"image": "/assets/Generation-1/127.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 65.68
	},
	{
		"id": 128,
		"image": "/assets/Generation-1/128.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 56.5
	},
	{
		"id": 129,
		"image": "/assets/Generation-1/129.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 52.72
	},
	{
		"id": 130,
		"image": "/assets/Generation-1/130.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 54.7
	},
	{
		"id": 131,
		"image": "/assets/Generation-1/131.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 61.42
	},
	{
		"id": 132,
		"image": "/assets/Generation-1/132.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.5
	},
	{
		"id": 133,
		"image": "/assets/Generation-1/133.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.21
	},
	{
		"id": 134,
		"image": "/assets/Generation-1/134.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.63
	},
	{
		"id": 135,
		"image": "/assets/Generation-1/135.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.25
	},
	{
		"id": 136,
		"image": "/assets/Generation-1/136.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.32
	},
	{
		"id": 137,
		"image": "/assets/Generation-1/137.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 75.55000000000001
	},
	{
		"id": 138,
		"image": "/assets/Generation-1/138.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.16
	},
	{
		"id": 139,
		"image": "/assets/Generation-1/139.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.80000000000001
	},
	{
		"id": 140,
		"image": "/assets/Generation-1/140.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 53.65
	},
	{
		"id": 141,
		"image": "/assets/Generation-1/141.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.25
	},
	{
		"id": 142,
		"image": "/assets/Generation-1/142.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 76.56
	},
	{
		"id": 143,
		"image": "/assets/Generation-1/143.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.36
	},
	{
		"id": 144,
		"image": "/assets/Generation-1/144.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.92
	},
	{
		"id": 145,
		"image": "/assets/Generation-1/145.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.47999999999999
	},
	{
		"id": 146,
		"image": "/assets/Generation-1/146.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 69.25
	},
	{
		"id": 147,
		"image": "/assets/Generation-1/147.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 71.1
	},
	{
		"id": 148,
		"image": "/assets/Generation-1/148.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.48
	},
	{
		"id": 149,
		"image": "/assets/Generation-1/149.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.92
	},
	{
		"id": 150,
		"image": "/assets/Generation-1/150.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.92
	},
	{
		"id": 151,
		"image": "/assets/Generation-1/151.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 152,
		"image": "/assets/Generation-1/152.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 153,
		"image": "/assets/Generation-1/153.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 154,
		"image": "/assets/Generation-1/154.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.45
	},
	{
		"id": 155,
		"image": "/assets/Generation-1/155.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.92
	},
	{
		"id": 156,
		"image": "/assets/Generation-1/156.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 58.9
	},
	{
		"id": 157,
		"image": "/assets/Generation-1/157.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.55000000000001
	},
	{
		"id": 158,
		"image": "/assets/Generation-1/158.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 74.1
	},
	{
		"id": 159,
		"image": "/assets/Generation-1/159.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 69.36
	},
	{
		"id": 160,
		"image": "/assets/Generation-1/160.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.75
	},
	{
		"id": 161,
		"image": "/assets/Generation-1/161.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.72
	},
	{
		"id": 162,
		"image": "/assets/Generation-1/162.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.2
	},
	{
		"id": 163,
		"image": "/assets/Generation-1/163.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.95
	},
	{
		"id": 164,
		"image": "/assets/Generation-1/164.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 58.72
	},
	{
		"id": 165,
		"image": "/assets/Generation-1/165.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 58.5
	},
	{
		"id": 166,
		"image": "/assets/Generation-1/166.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.6
	},
	{
		"id": 167,
		"image": "/assets/Generation-1/167.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 63.72
	},
	{
		"id": 168,
		"image": "/assets/Generation-1/168.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.42
	},
	{
		"id": 169,
		"image": "/assets/Generation-1/169.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.35
	},
	{
		"id": 170,
		"image": "/assets/Generation-1/170.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 171,
		"image": "/assets/Generation-1/171.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.92
	},
	{
		"id": 172,
		"image": "/assets/Generation-1/172.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.25
	},
	{
		"id": 173,
		"image": "/assets/Generation-1/173.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 174,
		"image": "/assets/Generation-1/174.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 60.65
	},
	{
		"id": 175,
		"image": "/assets/Generation-1/175.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.3
	},
	{
		"id": 176,
		"image": "/assets/Generation-1/176.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.6
	},
	{
		"id": 177,
		"image": "/assets/Generation-1/177.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.63
	},
	{
		"id": 178,
		"image": "/assets/Generation-1/178.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 62.7
	},
	{
		"id": 179,
		"image": "/assets/Generation-1/179.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.24000000000001
	},
	{
		"id": 180,
		"image": "/assets/Generation-1/180.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 181,
		"image": "/assets/Generation-1/181.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 70.65
	},
	{
		"id": 182,
		"image": "/assets/Generation-1/182.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 59.9
	},
	{
		"id": 183,
		"image": "/assets/Generation-1/183.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.72
	},
	{
		"id": 184,
		"image": "/assets/Generation-1/184.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 70.92
	},
	{
		"id": 185,
		"image": "/assets/Generation-1/185.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 186,
		"image": "/assets/Generation-1/186.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.11
	},
	{
		"id": 187,
		"image": "/assets/Generation-1/187.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.16
	},
	{
		"id": 188,
		"image": "/assets/Generation-1/188.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 56.04
	},
	{
		"id": 189,
		"image": "/assets/Generation-1/189.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.9
	},
	{
		"id": 190,
		"image": "/assets/Generation-1/190.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 60.52
	},
	{
		"id": 191,
		"image": "/assets/Generation-1/191.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 57.400000000000006
	},
	{
		"id": 192,
		"image": "/assets/Generation-1/192.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 59.7
	},
	{
		"id": 193,
		"image": "/assets/Generation-1/193.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 69.68
	},
	{
		"id": 194,
		"image": "/assets/Generation-1/194.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 58.05
	},
	{
		"id": 195,
		"image": "/assets/Generation-1/195.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 60.05
	},
	{
		"id": 196,
		"image": "/assets/Generation-1/196.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.63
	},
	{
		"id": 197,
		"image": "/assets/Generation-1/197.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 55.05
	},
	{
		"id": 198,
		"image": "/assets/Generation-1/198.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.52000000000001
	},
	{
		"id": 199,
		"image": "/assets/Generation-1/199.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 69.95
	},
	{
		"id": 200,
		"image": "/assets/Generation-1/200.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 58.05
	},
	{
		"id": 201,
		"image": "/assets/Generation-1/201.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 68.65
	},
	{
		"id": 202,
		"image": "/assets/Generation-1/202.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 70.91
	},
	{
		"id": 203,
		"image": "/assets/Generation-1/203.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.36
	},
	{
		"id": 204,
		"image": "/assets/Generation-1/204.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 205,
		"image": "/assets/Generation-1/205.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 66.24000000000001
	},
	{
		"id": 206,
		"image": "/assets/Generation-1/206.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.80000000000001
	},
	{
		"id": 207,
		"image": "/assets/Generation-1/207.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 63.900000000000006
	},
	{
		"id": 208,
		"image": "/assets/Generation-1/208.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 58.21
	},
	{
		"id": 209,
		"image": "/assets/Generation-1/209.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.72
	},
	{
		"id": 210,
		"image": "/assets/Generation-1/210.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.65
	},
	{
		"id": 211,
		"image": "/assets/Generation-1/211.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.65
	},
	{
		"id": 212,
		"image": "/assets/Generation-1/212.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 213,
		"image": "/assets/Generation-1/213.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 65.03999999999999
	},
	{
		"id": 214,
		"image": "/assets/Generation-1/214.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.95
	},
	{
		"id": 215,
		"image": "/assets/Generation-1/215.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 48.48
	},
	{
		"id": 216,
		"image": "/assets/Generation-1/216.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 68.03999999999999
	},
	{
		"id": 217,
		"image": "/assets/Generation-1/217.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.11
	},
	{
		"id": 218,
		"image": "/assets/Generation-1/218.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.32
	},
	{
		"id": 219,
		"image": "/assets/Generation-1/219.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 56.36
	},
	{
		"id": 220,
		"image": "/assets/Generation-1/220.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.24000000000001
	},
	{
		"id": 221,
		"image": "/assets/Generation-1/221.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.6
	},
	{
		"id": 222,
		"image": "/assets/Generation-1/222.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 223,
		"image": "/assets/Generation-1/223.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.5
	},
	{
		"id": 224,
		"image": "/assets/Generation-1/224.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.65
	},
	{
		"id": 225,
		"image": "/assets/Generation-1/225.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.41
	},
	{
		"id": 226,
		"image": "/assets/Generation-1/226.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.03999999999999
	},
	{
		"id": 227,
		"image": "/assets/Generation-1/227.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 71.49000000000001
	},
	{
		"id": 228,
		"image": "/assets/Generation-1/228.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.72
	},
	{
		"id": 229,
		"image": "/assets/Generation-1/229.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 76.92
	},
	{
		"id": 230,
		"image": "/assets/Generation-1/230.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Orange", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.47
	},
	{
		"id": 231,
		"image": "/assets/Generation-1/231.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.68
	},
	{
		"id": 232,
		"image": "/assets/Generation-1/232.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.36
	},
	{
		"id": 233,
		"image": "/assets/Generation-1/233.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 234,
		"image": "/assets/Generation-1/234.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 72.25
	},
	{
		"id": 235,
		"image": "/assets/Generation-1/235.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.2
	},
	{
		"id": 236,
		"image": "/assets/Generation-1/236.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 237,
		"image": "/assets/Generation-1/237.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 62.42
	},
	{
		"id": 238,
		"image": "/assets/Generation-1/238.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 239,
		"image": "/assets/Generation-1/239.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.9
	},
	{
		"id": 240,
		"image": "/assets/Generation-1/240.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 71.91
	},
	{
		"id": 241,
		"image": "/assets/Generation-1/241.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.95
	},
	{
		"id": 242,
		"image": "/assets/Generation-1/242.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.3
	},
	{
		"id": 243,
		"image": "/assets/Generation-1/243.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.92
	},
	{
		"id": 244,
		"image": "/assets/Generation-1/244.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 60.800000000000004
	},
	{
		"id": 245,
		"image": "/assets/Generation-1/245.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 246,
		"image": "/assets/Generation-1/246.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.92
	},
	{
		"id": 247,
		"image": "/assets/Generation-1/247.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 61.5
	},
	{
		"id": 248,
		"image": "/assets/Generation-1/248.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 75.83000000000001
	},
	{
		"id": 249,
		"image": "/assets/Generation-1/249.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 53.05
	},
	{
		"id": 250,
		"image": "/assets/Generation-1/250.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 251,
		"image": "/assets/Generation-1/251.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 57.91
	},
	{
		"id": 252,
		"image": "/assets/Generation-1/252.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.9
	},
	{
		"id": 253,
		"image": "/assets/Generation-1/253.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.7
	},
	{
		"id": 254,
		"image": "/assets/Generation-1/254.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.95
	},
	{
		"id": 255,
		"image": "/assets/Generation-1/255.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 60.45
	},
	{
		"id": 256,
		"image": "/assets/Generation-1/256.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.45
	},
	{
		"id": 257,
		"image": "/assets/Generation-1/257.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.72
	},
	{
		"id": 258,
		"image": "/assets/Generation-1/258.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.03999999999999
	},
	{
		"id": 259,
		"image": "/assets/Generation-1/259.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 260,
		"image": "/assets/Generation-1/260.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 58.25
	},
	{
		"id": 261,
		"image": "/assets/Generation-1/261.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.2
	},
	{
		"id": 262,
		"image": "/assets/Generation-1/262.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.7
	},
	{
		"id": 263,
		"image": "/assets/Generation-1/263.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 56.6
	},
	{
		"id": 264,
		"image": "/assets/Generation-1/264.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.68
	},
	{
		"id": 265,
		"image": "/assets/Generation-1/265.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.62
	},
	{
		"id": 266,
		"image": "/assets/Generation-1/266.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 40.6
	},
	{
		"id": 267,
		"image": "/assets/Generation-1/267.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 60.68
	},
	{
		"id": 268,
		"image": "/assets/Generation-1/268.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 269,
		"image": "/assets/Generation-1/269.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.9
	},
	{
		"id": 270,
		"image": "/assets/Generation-1/270.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.24000000000001
	},
	{
		"id": 271,
		"image": "/assets/Generation-1/271.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 272,
		"image": "/assets/Generation-1/272.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 60.72
	},
	{
		"id": 273,
		"image": "/assets/Generation-1/273.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.95
	},
	{
		"id": 274,
		"image": "/assets/Generation-1/274.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 275,
		"image": "/assets/Generation-1/275.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 276,
		"image": "/assets/Generation-1/276.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.05
	},
	{
		"id": 277,
		"image": "/assets/Generation-1/277.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.95
	},
	{
		"id": 278,
		"image": "/assets/Generation-1/278.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 66.72
	},
	{
		"id": 279,
		"image": "/assets/Generation-1/279.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.16
	},
	{
		"id": 280,
		"image": "/assets/Generation-1/280.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.1
	},
	{
		"id": 281,
		"image": "/assets/Generation-1/281.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 66.11
	},
	{
		"id": 282,
		"image": "/assets/Generation-1/282.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 61.45
	},
	{
		"id": 283,
		"image": "/assets/Generation-1/283.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.56
	},
	{
		"id": 284,
		"image": "/assets/Generation-1/284.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 285,
		"image": "/assets/Generation-1/285.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.42
	},
	{
		"id": 286,
		"image": "/assets/Generation-1/286.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 61.3
	},
	{
		"id": 287,
		"image": "/assets/Generation-1/287.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 288,
		"image": "/assets/Generation-1/288.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.56
	},
	{
		"id": 289,
		"image": "/assets/Generation-1/289.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 62.72
	},
	{
		"id": 290,
		"image": "/assets/Generation-1/290.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.9
	},
	{
		"id": 291,
		"image": "/assets/Generation-1/291.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.65
	},
	{
		"id": 292,
		"image": "/assets/Generation-1/292.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 49.7
	},
	{
		"id": 293,
		"image": "/assets/Generation-1/293.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.9
	},
	{
		"id": 294,
		"image": "/assets/Generation-1/294.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 59.6
	},
	{
		"id": 295,
		"image": "/assets/Generation-1/295.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 296,
		"image": "/assets/Generation-1/296.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 77.92
	},
	{
		"id": 297,
		"image": "/assets/Generation-1/297.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 298,
		"image": "/assets/Generation-1/298.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.05
	},
	{
		"id": 299,
		"image": "/assets/Generation-1/299.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 58.42
	},
	{
		"id": 300,
		"image": "/assets/Generation-1/300.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 301,
		"image": "/assets/Generation-1/301.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 70.55000000000001
	},
	{
		"id": 302,
		"image": "/assets/Generation-1/302.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 303,
		"image": "/assets/Generation-1/303.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.80000000000001
	},
	{
		"id": 304,
		"image": "/assets/Generation-1/304.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.75
	},
	{
		"id": 305,
		"image": "/assets/Generation-1/305.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 306,
		"image": "/assets/Generation-1/306.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.52000000000001
	},
	{
		"id": 307,
		"image": "/assets/Generation-1/307.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 64.59
	},
	{
		"id": 308,
		"image": "/assets/Generation-1/308.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.83000000000001
	},
	{
		"id": 309,
		"image": "/assets/Generation-1/309.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 58.05
	},
	{
		"id": 310,
		"image": "/assets/Generation-1/310.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.56
	},
	{
		"id": 311,
		"image": "/assets/Generation-1/311.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.36
	},
	{
		"id": 312,
		"image": "/assets/Generation-1/312.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.36
	},
	{
		"id": 313,
		"image": "/assets/Generation-1/313.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 59.800000000000004
	},
	{
		"id": 314,
		"image": "/assets/Generation-1/314.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.3
	},
	{
		"id": 315,
		"image": "/assets/Generation-1/315.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 54.42
	},
	{
		"id": 316,
		"image": "/assets/Generation-1/316.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 77.25
	},
	{
		"id": 317,
		"image": "/assets/Generation-1/317.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.2
	},
	{
		"id": 318,
		"image": "/assets/Generation-1/318.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 67.42
	},
	{
		"id": 319,
		"image": "/assets/Generation-1/319.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 70.83000000000001
	},
	{
		"id": 320,
		"image": "/assets/Generation-1/320.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.56
	},
	{
		"id": 321,
		"image": "/assets/Generation-1/321.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.62
	},
	{
		"id": 322,
		"image": "/assets/Generation-1/322.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 66.59
	},
	{
		"id": 323,
		"image": "/assets/Generation-1/323.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 51.75
	},
	{
		"id": 324,
		"image": "/assets/Generation-1/324.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.91
	},
	{
		"id": 325,
		"image": "/assets/Generation-1/325.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 63.2
	},
	{
		"id": 326,
		"image": "/assets/Generation-1/326.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.11
	},
	{
		"id": 327,
		"image": "/assets/Generation-1/327.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.49000000000001
	},
	{
		"id": 328,
		"image": "/assets/Generation-1/328.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 57.63
	},
	{
		"id": 329,
		"image": "/assets/Generation-1/329.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.83000000000001
	},
	{
		"id": 330,
		"image": "/assets/Generation-1/330.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.16
	},
	{
		"id": 331,
		"image": "/assets/Generation-1/331.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 75.4
	},
	{
		"id": 332,
		"image": "/assets/Generation-1/332.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 62.05
	},
	{
		"id": 333,
		"image": "/assets/Generation-1/333.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.25
	},
	{
		"id": 334,
		"image": "/assets/Generation-1/334.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 52.27
	},
	{
		"id": 335,
		"image": "/assets/Generation-1/335.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.56
	},
	{
		"id": 336,
		"image": "/assets/Generation-1/336.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.4
	},
	{
		"id": 337,
		"image": "/assets/Generation-1/337.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.92
	},
	{
		"id": 338,
		"image": "/assets/Generation-1/338.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 339,
		"image": "/assets/Generation-1/339.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.91
	},
	{
		"id": 340,
		"image": "/assets/Generation-1/340.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 341,
		"image": "/assets/Generation-1/341.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 59.75
	},
	{
		"id": 342,
		"image": "/assets/Generation-1/342.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.45
	},
	{
		"id": 343,
		"image": "/assets/Generation-1/343.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.41
	},
	{
		"id": 344,
		"image": "/assets/Generation-1/344.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 73.25
	},
	{
		"id": 345,
		"image": "/assets/Generation-1/345.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.72
	},
	{
		"id": 346,
		"image": "/assets/Generation-1/346.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.4
	},
	{
		"id": 347,
		"image": "/assets/Generation-1/347.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.05
	},
	{
		"id": 348,
		"image": "/assets/Generation-1/348.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 68.4
	},
	{
		"id": 349,
		"image": "/assets/Generation-1/349.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 70.68
	},
	{
		"id": 350,
		"image": "/assets/Generation-1/350.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 67.83000000000001
	},
	{
		"id": 351,
		"image": "/assets/Generation-1/351.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.05
	},
	{
		"id": 352,
		"image": "/assets/Generation-1/352.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.36
	},
	{
		"id": 353,
		"image": "/assets/Generation-1/353.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.56
	},
	{
		"id": 354,
		"image": "/assets/Generation-1/354.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 355,
		"image": "/assets/Generation-1/355.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.52000000000001
	},
	{
		"id": 356,
		"image": "/assets/Generation-1/356.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 357,
		"image": "/assets/Generation-1/357.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 358,
		"image": "/assets/Generation-1/358.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.80000000000001
	},
	{
		"id": 359,
		"image": "/assets/Generation-1/359.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.42
	},
	{
		"id": 360,
		"image": "/assets/Generation-1/360.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 67.63
	},
	{
		"id": 361,
		"image": "/assets/Generation-1/361.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 55.6
	},
	{
		"id": 362,
		"image": "/assets/Generation-1/362.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.35
	},
	{
		"id": 363,
		"image": "/assets/Generation-1/363.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 70.36
	},
	{
		"id": 364,
		"image": "/assets/Generation-1/364.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.1
	},
	{
		"id": 365,
		"image": "/assets/Generation-1/365.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.05
	},
	{
		"id": 366,
		"image": "/assets/Generation-1/366.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.9
	},
	{
		"id": 367,
		"image": "/assets/Generation-1/367.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 368,
		"image": "/assets/Generation-1/368.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 70.52000000000001
	},
	{
		"id": 369,
		"image": "/assets/Generation-1/369.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 59.75
	},
	{
		"id": 370,
		"image": "/assets/Generation-1/370.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.9
	},
	{
		"id": 371,
		"image": "/assets/Generation-1/371.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.39
	},
	{
		"id": 372,
		"image": "/assets/Generation-1/372.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 373,
		"image": "/assets/Generation-1/373.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.2
	},
	{
		"id": 374,
		"image": "/assets/Generation-1/374.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.91
	},
	{
		"id": 375,
		"image": "/assets/Generation-1/375.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 376,
		"image": "/assets/Generation-1/376.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 377,
		"image": "/assets/Generation-1/377.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.56
	},
	{
		"id": 378,
		"image": "/assets/Generation-1/378.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 63.75
	},
	{
		"id": 379,
		"image": "/assets/Generation-1/379.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 62.05
	},
	{
		"id": 380,
		"image": "/assets/Generation-1/380.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.72
	},
	{
		"id": 381,
		"image": "/assets/Generation-1/381.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 47.56
	},
	{
		"id": 382,
		"image": "/assets/Generation-1/382.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.92
	},
	{
		"id": 383,
		"image": "/assets/Generation-1/383.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 384,
		"image": "/assets/Generation-1/384.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 53.620000000000005
	},
	{
		"id": 385,
		"image": "/assets/Generation-1/385.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 50.36
	},
	{
		"id": 386,
		"image": "/assets/Generation-1/386.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.75
	},
	{
		"id": 387,
		"image": "/assets/Generation-1/387.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.1
	},
	{
		"id": 388,
		"image": "/assets/Generation-1/388.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.91
	},
	{
		"id": 389,
		"image": "/assets/Generation-1/389.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 63.9
	},
	{
		"id": 390,
		"image": "/assets/Generation-1/390.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.62
	},
	{
		"id": 391,
		"image": "/assets/Generation-1/391.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 63.9
	},
	{
		"id": 392,
		"image": "/assets/Generation-1/392.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.16
	},
	{
		"id": 393,
		"image": "/assets/Generation-1/393.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.75
	},
	{
		"id": 394,
		"image": "/assets/Generation-1/394.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.72
	},
	{
		"id": 395,
		"image": "/assets/Generation-1/395.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 57.05
	},
	{
		"id": 396,
		"image": "/assets/Generation-1/396.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.63
	},
	{
		"id": 397,
		"image": "/assets/Generation-1/397.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.6
	},
	{
		"id": 398,
		"image": "/assets/Generation-1/398.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.63
	},
	{
		"id": 399,
		"image": "/assets/Generation-1/399.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.80000000000001
	},
	{
		"id": 400,
		"image": "/assets/Generation-1/400.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 401,
		"image": "/assets/Generation-1/401.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.7
	},
	{
		"id": 402,
		"image": "/assets/Generation-1/402.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.72
	},
	{
		"id": 403,
		"image": "/assets/Generation-1/403.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.62
	},
	{
		"id": 404,
		"image": "/assets/Generation-1/404.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 405,
		"image": "/assets/Generation-1/405.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.48
	},
	{
		"id": 406,
		"image": "/assets/Generation-1/406.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 407,
		"image": "/assets/Generation-1/407.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 50.35
	},
	{
		"id": 408,
		"image": "/assets/Generation-1/408.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 57.9
	},
	{
		"id": 409,
		"image": "/assets/Generation-1/409.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 63.65
	},
	{
		"id": 410,
		"image": "/assets/Generation-1/410.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.35
	},
	{
		"id": 411,
		"image": "/assets/Generation-1/411.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 58.1
	},
	{
		"id": 412,
		"image": "/assets/Generation-1/412.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.95
	},
	{
		"id": 413,
		"image": "/assets/Generation-1/413.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.56
	},
	{
		"id": 414,
		"image": "/assets/Generation-1/414.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 75.68
	},
	{
		"id": 415,
		"image": "/assets/Generation-1/415.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 56.35
	},
	{
		"id": 416,
		"image": "/assets/Generation-1/416.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 59.9
	},
	{
		"id": 417,
		"image": "/assets/Generation-1/417.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.65
	},
	{
		"id": 418,
		"image": "/assets/Generation-1/418.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.05
	},
	{
		"id": 419,
		"image": "/assets/Generation-1/419.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 63.04
	},
	{
		"id": 420,
		"image": "/assets/Generation-1/420.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 421,
		"image": "/assets/Generation-1/421.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.9
	},
	{
		"id": 422,
		"image": "/assets/Generation-1/422.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 75.80000000000001
	},
	{
		"id": 423,
		"image": "/assets/Generation-1/423.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.95
	},
	{
		"id": 424,
		"image": "/assets/Generation-1/424.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 58.32
	},
	{
		"id": 425,
		"image": "/assets/Generation-1/425.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.63
	},
	{
		"id": 426,
		"image": "/assets/Generation-1/426.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.2
	},
	{
		"id": 427,
		"image": "/assets/Generation-1/427.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.7
	},
	{
		"id": 428,
		"image": "/assets/Generation-1/428.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 429,
		"image": "/assets/Generation-1/429.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.1
	},
	{
		"id": 430,
		"image": "/assets/Generation-1/430.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.21
	},
	{
		"id": 431,
		"image": "/assets/Generation-1/431.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 73.47999999999999
	},
	{
		"id": 432,
		"image": "/assets/Generation-1/432.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.05
	},
	{
		"id": 433,
		"image": "/assets/Generation-1/433.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 76.1
	},
	{
		"id": 434,
		"image": "/assets/Generation-1/434.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.5
	},
	{
		"id": 435,
		"image": "/assets/Generation-1/435.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.05
	},
	{
		"id": 436,
		"image": "/assets/Generation-1/436.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 62.620000000000005
	},
	{
		"id": 437,
		"image": "/assets/Generation-1/437.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 62.6
	},
	{
		"id": 438,
		"image": "/assets/Generation-1/438.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 61.36
	},
	{
		"id": 439,
		"image": "/assets/Generation-1/439.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 79.91
	},
	{
		"id": 440,
		"image": "/assets/Generation-1/440.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.76
	},
	{
		"id": 441,
		"image": "/assets/Generation-1/441.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 442,
		"image": "/assets/Generation-1/442.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.83000000000001
	},
	{
		"id": 443,
		"image": "/assets/Generation-1/443.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.47999999999999
	},
	{
		"id": 444,
		"image": "/assets/Generation-1/444.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.25
	},
	{
		"id": 445,
		"image": "/assets/Generation-1/445.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.75
	},
	{
		"id": 446,
		"image": "/assets/Generation-1/446.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 51.830000000000005
	},
	{
		"id": 447,
		"image": "/assets/Generation-1/447.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.68
	},
	{
		"id": 448,
		"image": "/assets/Generation-1/448.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 62.2
	},
	{
		"id": 449,
		"image": "/assets/Generation-1/449.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 50.7
	},
	{
		"id": 450,
		"image": "/assets/Generation-1/450.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.63
	},
	{
		"id": 451,
		"image": "/assets/Generation-1/451.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 58.16
	},
	{
		"id": 452,
		"image": "/assets/Generation-1/452.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 453,
		"image": "/assets/Generation-1/453.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 62.5
	},
	{
		"id": 454,
		"image": "/assets/Generation-1/454.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 70.55000000000001
	},
	{
		"id": 455,
		"image": "/assets/Generation-1/455.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.83000000000001
	},
	{
		"id": 456,
		"image": "/assets/Generation-1/456.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 457,
		"image": "/assets/Generation-1/457.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 59.900000000000006
	},
	{
		"id": 458,
		"image": "/assets/Generation-1/458.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 44.72
	},
	{
		"id": 459,
		"image": "/assets/Generation-1/459.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 60.900000000000006
	},
	{
		"id": 460,
		"image": "/assets/Generation-1/460.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 71.6
	},
	{
		"id": 461,
		"image": "/assets/Generation-1/461.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 62.5
	},
	{
		"id": 462,
		"image": "/assets/Generation-1/462.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.72
	},
	{
		"id": 463,
		"image": "/assets/Generation-1/463.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.5
	},
	{
		"id": 464,
		"image": "/assets/Generation-1/464.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.92
	},
	{
		"id": 465,
		"image": "/assets/Generation-1/465.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 59.35
	},
	{
		"id": 466,
		"image": "/assets/Generation-1/466.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 61.21
	},
	{
		"id": 467,
		"image": "/assets/Generation-1/467.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 57.56
	},
	{
		"id": 468,
		"image": "/assets/Generation-1/468.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.75
	},
	{
		"id": 469,
		"image": "/assets/Generation-1/469.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.95
	},
	{
		"id": 470,
		"image": "/assets/Generation-1/470.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.1
	},
	{
		"id": 471,
		"image": "/assets/Generation-1/471.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 60.36
	},
	{
		"id": 472,
		"image": "/assets/Generation-1/472.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 69.6
	},
	{
		"id": 473,
		"image": "/assets/Generation-1/473.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.9
	},
	{
		"id": 474,
		"image": "/assets/Generation-1/474.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 50.72
	},
	{
		"id": 475,
		"image": "/assets/Generation-1/475.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.3
	},
	{
		"id": 476,
		"image": "/assets/Generation-1/476.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 477,
		"image": "/assets/Generation-1/477.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.62
	},
	{
		"id": 478,
		"image": "/assets/Generation-1/478.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 66.65
	},
	{
		"id": 479,
		"image": "/assets/Generation-1/479.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.16
	},
	{
		"id": 480,
		"image": "/assets/Generation-1/480.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 481,
		"image": "/assets/Generation-1/481.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.62
	},
	{
		"id": 482,
		"image": "/assets/Generation-1/482.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.62
	},
	{
		"id": 483,
		"image": "/assets/Generation-1/483.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 68.25
	},
	{
		"id": 484,
		"image": "/assets/Generation-1/484.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.91
	},
	{
		"id": 485,
		"image": "/assets/Generation-1/485.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 73.5
	},
	{
		"id": 486,
		"image": "/assets/Generation-1/486.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 60.42
	},
	{
		"id": 487,
		"image": "/assets/Generation-1/487.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 58.95
	},
	{
		"id": 488,
		"image": "/assets/Generation-1/488.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.9
	},
	{
		"id": 489,
		"image": "/assets/Generation-1/489.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.25
	},
	{
		"id": 490,
		"image": "/assets/Generation-1/490.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.63
	},
	{
		"id": 491,
		"image": "/assets/Generation-1/491.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 66.3
	},
	{
		"id": 492,
		"image": "/assets/Generation-1/492.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.92
	},
	{
		"id": 493,
		"image": "/assets/Generation-1/493.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 81.5
	},
	{
		"id": 494,
		"image": "/assets/Generation-1/494.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.52000000000001
	},
	{
		"id": 495,
		"image": "/assets/Generation-1/495.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 496,
		"image": "/assets/Generation-1/496.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.9
	},
	{
		"id": 497,
		"image": "/assets/Generation-1/497.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.36
	},
	{
		"id": 498,
		"image": "/assets/Generation-1/498.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 499,
		"image": "/assets/Generation-1/499.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.65
	},
	{
		"id": 500,
		"image": "/assets/Generation-1/500.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 52.6
	},
	{
		"id": 501,
		"image": "/assets/Generation-1/501.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.62
	},
	{
		"id": 502,
		"image": "/assets/Generation-1/502.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.36
	},
	{
		"id": 503,
		"image": "/assets/Generation-1/503.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 56.690000000000005
	},
	{
		"id": 504,
		"image": "/assets/Generation-1/504.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.80000000000001
	},
	{
		"id": 505,
		"image": "/assets/Generation-1/505.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 62.25
	},
	{
		"id": 506,
		"image": "/assets/Generation-1/506.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 68.36
	},
	{
		"id": 507,
		"image": "/assets/Generation-1/507.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.24000000000001
	},
	{
		"id": 508,
		"image": "/assets/Generation-1/508.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.16
	},
	{
		"id": 509,
		"image": "/assets/Generation-1/509.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.5
	},
	{
		"id": 510,
		"image": "/assets/Generation-1/510.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 50.48
	},
	{
		"id": 511,
		"image": "/assets/Generation-1/511.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 77.68
	},
	{
		"id": 512,
		"image": "/assets/Generation-1/512.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 58.35
	},
	{
		"id": 513,
		"image": "/assets/Generation-1/513.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.59
	},
	{
		"id": 514,
		"image": "/assets/Generation-1/514.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 62.72
	},
	{
		"id": 515,
		"image": "/assets/Generation-1/515.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.5
	},
	{
		"id": 516,
		"image": "/assets/Generation-1/516.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.92
	},
	{
		"id": 517,
		"image": "/assets/Generation-1/517.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 43.91
	},
	{
		"id": 518,
		"image": "/assets/Generation-1/518.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.65
	},
	{
		"id": 519,
		"image": "/assets/Generation-1/519.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.27000000000001
	},
	{
		"id": 520,
		"image": "/assets/Generation-1/520.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 75.85
	},
	{
		"id": 521,
		"image": "/assets/Generation-1/521.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 70.91
	},
	{
		"id": 522,
		"image": "/assets/Generation-1/522.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.45
	},
	{
		"id": 523,
		"image": "/assets/Generation-1/523.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.56
	},
	{
		"id": 524,
		"image": "/assets/Generation-1/524.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.2
	},
	{
		"id": 525,
		"image": "/assets/Generation-1/525.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 76.80000000000001
	},
	{
		"id": 526,
		"image": "/assets/Generation-1/526.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 74.52000000000001
	},
	{
		"id": 527,
		"image": "/assets/Generation-1/527.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.63
	},
	{
		"id": 528,
		"image": "/assets/Generation-1/528.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.80000000000001
	},
	{
		"id": 529,
		"image": "/assets/Generation-1/529.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 75.76
	},
	{
		"id": 530,
		"image": "/assets/Generation-1/530.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 79.24000000000001
	},
	{
		"id": 531,
		"image": "/assets/Generation-1/531.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.92
	},
	{
		"id": 532,
		"image": "/assets/Generation-1/532.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.11
	},
	{
		"id": 533,
		"image": "/assets/Generation-1/533.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 534,
		"image": "/assets/Generation-1/534.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 535,
		"image": "/assets/Generation-1/535.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 66.9
	},
	{
		"id": 536,
		"image": "/assets/Generation-1/536.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.63
	},
	{
		"id": 537,
		"image": "/assets/Generation-1/537.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.65
	},
	{
		"id": 538,
		"image": "/assets/Generation-1/538.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 59.72
	},
	{
		"id": 539,
		"image": "/assets/Generation-1/539.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.72
	},
	{
		"id": 540,
		"image": "/assets/Generation-1/540.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 62.56
	},
	{
		"id": 541,
		"image": "/assets/Generation-1/541.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.92
	},
	{
		"id": 542,
		"image": "/assets/Generation-1/542.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.95
	},
	{
		"id": 543,
		"image": "/assets/Generation-1/543.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 59.11
	},
	{
		"id": 544,
		"image": "/assets/Generation-1/544.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 44.45
	},
	{
		"id": 545,
		"image": "/assets/Generation-1/545.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.3
	},
	{
		"id": 546,
		"image": "/assets/Generation-1/546.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 58.42
	},
	{
		"id": 547,
		"image": "/assets/Generation-1/547.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 548,
		"image": "/assets/Generation-1/548.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 58.05
	},
	{
		"id": 549,
		"image": "/assets/Generation-1/549.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 57.36
	},
	{
		"id": 550,
		"image": "/assets/Generation-1/550.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.24000000000001
	},
	{
		"id": 551,
		"image": "/assets/Generation-1/551.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.4
	},
	{
		"id": 552,
		"image": "/assets/Generation-1/552.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 553,
		"image": "/assets/Generation-1/553.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.04
	},
	{
		"id": 554,
		"image": "/assets/Generation-1/554.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.63
	},
	{
		"id": 555,
		"image": "/assets/Generation-1/555.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 75.47
	},
	{
		"id": 556,
		"image": "/assets/Generation-1/556.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.11
	},
	{
		"id": 557,
		"image": "/assets/Generation-1/557.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.76
	},
	{
		"id": 558,
		"image": "/assets/Generation-1/558.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 69.3
	},
	{
		"id": 559,
		"image": "/assets/Generation-1/559.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 69.36
	},
	{
		"id": 560,
		"image": "/assets/Generation-1/560.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.72
	},
	{
		"id": 561,
		"image": "/assets/Generation-1/561.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 69.11
	},
	{
		"id": 562,
		"image": "/assets/Generation-1/562.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 50.63
	},
	{
		"id": 563,
		"image": "/assets/Generation-1/563.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 564,
		"image": "/assets/Generation-1/564.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 73.92
	},
	{
		"id": 565,
		"image": "/assets/Generation-1/565.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 60.6
	},
	{
		"id": 566,
		"image": "/assets/Generation-1/566.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 72.6
	},
	{
		"id": 567,
		"image": "/assets/Generation-1/567.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 55.05
	},
	{
		"id": 568,
		"image": "/assets/Generation-1/568.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 54.63
	},
	{
		"id": 569,
		"image": "/assets/Generation-1/569.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.05
	},
	{
		"id": 570,
		"image": "/assets/Generation-1/570.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 571,
		"image": "/assets/Generation-1/571.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 56.6
	},
	{
		"id": 572,
		"image": "/assets/Generation-1/572.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.04
	},
	{
		"id": 573,
		"image": "/assets/Generation-1/573.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 61.32
	},
	{
		"id": 574,
		"image": "/assets/Generation-1/574.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.80000000000001
	},
	{
		"id": 575,
		"image": "/assets/Generation-1/575.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 576,
		"image": "/assets/Generation-1/576.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 72.6
	},
	{
		"id": 577,
		"image": "/assets/Generation-1/577.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.16
	},
	{
		"id": 578,
		"image": "/assets/Generation-1/578.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.9
	},
	{
		"id": 579,
		"image": "/assets/Generation-1/579.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 57.2
	},
	{
		"id": 580,
		"image": "/assets/Generation-1/580.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 581,
		"image": "/assets/Generation-1/581.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.65
	},
	{
		"id": 582,
		"image": "/assets/Generation-1/582.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.41
	},
	{
		"id": 583,
		"image": "/assets/Generation-1/583.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.91
	},
	{
		"id": 584,
		"image": "/assets/Generation-1/584.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.6
	},
	{
		"id": 585,
		"image": "/assets/Generation-1/585.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.3
	},
	{
		"id": 586,
		"image": "/assets/Generation-1/586.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 62.5
	},
	{
		"id": 587,
		"image": "/assets/Generation-1/587.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.05
	},
	{
		"id": 588,
		"image": "/assets/Generation-1/588.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.9
	},
	{
		"id": 589,
		"image": "/assets/Generation-1/589.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 76.36
	},
	{
		"id": 590,
		"image": "/assets/Generation-1/590.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 59.760000000000005
	},
	{
		"id": 591,
		"image": "/assets/Generation-1/591.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 53.72
	},
	{
		"id": 592,
		"image": "/assets/Generation-1/592.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.9
	},
	{
		"id": 593,
		"image": "/assets/Generation-1/593.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 60.5
	},
	{
		"id": 594,
		"image": "/assets/Generation-1/594.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 64.75
	},
	{
		"id": 595,
		"image": "/assets/Generation-1/595.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.42
	},
	{
		"id": 596,
		"image": "/assets/Generation-1/596.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 57.91
	},
	{
		"id": 597,
		"image": "/assets/Generation-1/597.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 598,
		"image": "/assets/Generation-1/598.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.6
	},
	{
		"id": 599,
		"image": "/assets/Generation-1/599.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 62.91
	},
	{
		"id": 600,
		"image": "/assets/Generation-1/600.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 56.400000000000006
	},
	{
		"id": 601,
		"image": "/assets/Generation-1/601.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 49.72
	},
	{
		"id": 602,
		"image": "/assets/Generation-1/602.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 57.7
	},
	{
		"id": 603,
		"image": "/assets/Generation-1/603.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 51.21
	},
	{
		"id": 604,
		"image": "/assets/Generation-1/604.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 605,
		"image": "/assets/Generation-1/605.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.2
	},
	{
		"id": 606,
		"image": "/assets/Generation-1/606.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 607,
		"image": "/assets/Generation-1/607.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.5
	},
	{
		"id": 608,
		"image": "/assets/Generation-1/608.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.62
	},
	{
		"id": 609,
		"image": "/assets/Generation-1/609.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 610,
		"image": "/assets/Generation-1/610.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 77.83000000000001
	},
	{
		"id": 611,
		"image": "/assets/Generation-1/611.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.42
	},
	{
		"id": 612,
		"image": "/assets/Generation-1/612.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.11
	},
	{
		"id": 613,
		"image": "/assets/Generation-1/613.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 61.35
	},
	{
		"id": 614,
		"image": "/assets/Generation-1/614.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.36
	},
	{
		"id": 615,
		"image": "/assets/Generation-1/615.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 56.24
	},
	{
		"id": 616,
		"image": "/assets/Generation-1/616.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 74.65
	},
	{
		"id": 617,
		"image": "/assets/Generation-1/617.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.45
	},
	{
		"id": 618,
		"image": "/assets/Generation-1/618.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 619,
		"image": "/assets/Generation-1/619.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 620,
		"image": "/assets/Generation-1/620.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 62.72
	},
	{
		"id": 621,
		"image": "/assets/Generation-1/621.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.62
	},
	{
		"id": 622,
		"image": "/assets/Generation-1/622.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.1
	},
	{
		"id": 623,
		"image": "/assets/Generation-1/623.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 624,
		"image": "/assets/Generation-1/624.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.2
	},
	{
		"id": 625,
		"image": "/assets/Generation-1/625.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 73.25
	},
	{
		"id": 626,
		"image": "/assets/Generation-1/626.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 627,
		"image": "/assets/Generation-1/627.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.4
	},
	{
		"id": 628,
		"image": "/assets/Generation-1/628.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 76.25
	},
	{
		"id": 629,
		"image": "/assets/Generation-1/629.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.7
	},
	{
		"id": 630,
		"image": "/assets/Generation-1/630.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 631,
		"image": "/assets/Generation-1/631.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.7
	},
	{
		"id": 632,
		"image": "/assets/Generation-1/632.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 73.7
	},
	{
		"id": 633,
		"image": "/assets/Generation-1/633.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 64.5
	},
	{
		"id": 634,
		"image": "/assets/Generation-1/634.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 59.72
	},
	{
		"id": 635,
		"image": "/assets/Generation-1/635.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 636,
		"image": "/assets/Generation-1/636.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 63.620000000000005
	},
	{
		"id": 637,
		"image": "/assets/Generation-1/637.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 64.5
	},
	{
		"id": 638,
		"image": "/assets/Generation-1/638.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 62.75
	},
	{
		"id": 639,
		"image": "/assets/Generation-1/639.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 57.92
	},
	{
		"id": 640,
		"image": "/assets/Generation-1/640.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 65.21000000000001
	},
	{
		"id": 641,
		"image": "/assets/Generation-1/641.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 52.72
	},
	{
		"id": 642,
		"image": "/assets/Generation-1/642.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.63
	},
	{
		"id": 643,
		"image": "/assets/Generation-1/643.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 72.9
	},
	{
		"id": 644,
		"image": "/assets/Generation-1/644.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 64.42
	},
	{
		"id": 645,
		"image": "/assets/Generation-1/645.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.68
	},
	{
		"id": 646,
		"image": "/assets/Generation-1/646.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 49.05
	},
	{
		"id": 647,
		"image": "/assets/Generation-1/647.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.72
	},
	{
		"id": 648,
		"image": "/assets/Generation-1/648.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.4
	},
	{
		"id": 649,
		"image": "/assets/Generation-1/649.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 650,
		"image": "/assets/Generation-1/650.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 68.75
	},
	{
		"id": 651,
		"image": "/assets/Generation-1/651.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.03999999999999
	},
	{
		"id": 652,
		"image": "/assets/Generation-1/652.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 62.42
	},
	{
		"id": 653,
		"image": "/assets/Generation-1/653.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 62.7
	},
	{
		"id": 654,
		"image": "/assets/Generation-1/654.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.25
	},
	{
		"id": 655,
		"image": "/assets/Generation-1/655.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.35
	},
	{
		"id": 656,
		"image": "/assets/Generation-1/656.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.47999999999999
	},
	{
		"id": 657,
		"image": "/assets/Generation-1/657.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 64.16
	},
	{
		"id": 658,
		"image": "/assets/Generation-1/658.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.75
	},
	{
		"id": 659,
		"image": "/assets/Generation-1/659.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.83000000000001
	},
	{
		"id": 660,
		"image": "/assets/Generation-1/660.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 66.72
	},
	{
		"id": 661,
		"image": "/assets/Generation-1/661.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 662,
		"image": "/assets/Generation-1/662.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.47999999999999
	},
	{
		"id": 663,
		"image": "/assets/Generation-1/663.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.92
	},
	{
		"id": 664,
		"image": "/assets/Generation-1/664.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.5
	},
	{
		"id": 665,
		"image": "/assets/Generation-1/665.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 75.36
	},
	{
		"id": 666,
		"image": "/assets/Generation-1/666.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 64.5
	},
	{
		"id": 667,
		"image": "/assets/Generation-1/667.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.1
	},
	{
		"id": 668,
		"image": "/assets/Generation-1/668.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 669,
		"image": "/assets/Generation-1/669.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 70.5
	},
	{
		"id": 670,
		"image": "/assets/Generation-1/670.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.76
	},
	{
		"id": 671,
		"image": "/assets/Generation-1/671.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.6
	},
	{
		"id": 672,
		"image": "/assets/Generation-1/672.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.92
	},
	{
		"id": 673,
		"image": "/assets/Generation-1/673.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.63
	},
	{
		"id": 674,
		"image": "/assets/Generation-1/674.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.45
	},
	{
		"id": 675,
		"image": "/assets/Generation-1/675.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.68
	},
	{
		"id": 676,
		"image": "/assets/Generation-1/676.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.04
	},
	{
		"id": 677,
		"image": "/assets/Generation-1/677.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.75
	},
	{
		"id": 678,
		"image": "/assets/Generation-1/678.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.6
	},
	{
		"id": 679,
		"image": "/assets/Generation-1/679.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 59.7
	},
	{
		"id": 680,
		"image": "/assets/Generation-1/680.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.36
	},
	{
		"id": 681,
		"image": "/assets/Generation-1/681.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 51.59
	},
	{
		"id": 682,
		"image": "/assets/Generation-1/682.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 58.800000000000004
	},
	{
		"id": 683,
		"image": "/assets/Generation-1/683.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.55000000000001
	},
	{
		"id": 684,
		"image": "/assets/Generation-1/684.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 61.800000000000004
	},
	{
		"id": 685,
		"image": "/assets/Generation-1/685.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 62.6
	},
	{
		"id": 686,
		"image": "/assets/Generation-1/686.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 62.95
	},
	{
		"id": 687,
		"image": "/assets/Generation-1/687.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 54.25
	},
	{
		"id": 688,
		"image": "/assets/Generation-1/688.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.72
	},
	{
		"id": 689,
		"image": "/assets/Generation-1/689.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.69
	},
	{
		"id": 690,
		"image": "/assets/Generation-1/690.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 59.56
	},
	{
		"id": 691,
		"image": "/assets/Generation-1/691.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 692,
		"image": "/assets/Generation-1/692.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 693,
		"image": "/assets/Generation-1/693.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.62
	},
	{
		"id": 694,
		"image": "/assets/Generation-1/694.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 57.800000000000004
	},
	{
		"id": 695,
		"image": "/assets/Generation-1/695.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.36
	},
	{
		"id": 696,
		"image": "/assets/Generation-1/696.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 70.95
	},
	{
		"id": 697,
		"image": "/assets/Generation-1/697.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.2
	},
	{
		"id": 698,
		"image": "/assets/Generation-1/698.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 55.04
	},
	{
		"id": 699,
		"image": "/assets/Generation-1/699.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 58.52
	},
	{
		"id": 700,
		"image": "/assets/Generation-1/700.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 58.800000000000004
	},
	{
		"id": 701,
		"image": "/assets/Generation-1/701.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.03999999999999
	},
	{
		"id": 702,
		"image": "/assets/Generation-1/702.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.7
	},
	{
		"id": 703,
		"image": "/assets/Generation-1/703.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 43.9
	},
	{
		"id": 704,
		"image": "/assets/Generation-1/704.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 66.42
	},
	{
		"id": 705,
		"image": "/assets/Generation-1/705.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.24000000000001
	},
	{
		"id": 706,
		"image": "/assets/Generation-1/706.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 70.47999999999999
	},
	{
		"id": 707,
		"image": "/assets/Generation-1/707.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 55.52
	},
	{
		"id": 708,
		"image": "/assets/Generation-1/708.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 77.4
	},
	{
		"id": 709,
		"image": "/assets/Generation-1/709.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 67.42
	},
	{
		"id": 710,
		"image": "/assets/Generation-1/710.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 59.9
	},
	{
		"id": 711,
		"image": "/assets/Generation-1/711.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 76.24000000000001
	},
	{
		"id": 712,
		"image": "/assets/Generation-1/712.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.5
	},
	{
		"id": 713,
		"image": "/assets/Generation-1/713.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.52000000000001
	},
	{
		"id": 714,
		"image": "/assets/Generation-1/714.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 72.80000000000001
	},
	{
		"id": 715,
		"image": "/assets/Generation-1/715.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 52.05
	},
	{
		"id": 716,
		"image": "/assets/Generation-1/716.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.05
	},
	{
		"id": 717,
		"image": "/assets/Generation-1/717.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.4
	},
	{
		"id": 718,
		"image": "/assets/Generation-1/718.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 56.5
	},
	{
		"id": 719,
		"image": "/assets/Generation-1/719.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.5
	},
	{
		"id": 720,
		"image": "/assets/Generation-1/720.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 72.62
	},
	{
		"id": 721,
		"image": "/assets/Generation-1/721.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 57.900000000000006
	},
	{
		"id": 722,
		"image": "/assets/Generation-1/722.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 52.92
	},
	{
		"id": 723,
		"image": "/assets/Generation-1/723.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.52000000000001
	},
	{
		"id": 724,
		"image": "/assets/Generation-1/724.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.32
	},
	{
		"id": 725,
		"image": "/assets/Generation-1/725.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.7
	},
	{
		"id": 726,
		"image": "/assets/Generation-1/726.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 80.56
	},
	{
		"id": 727,
		"image": "/assets/Generation-1/727.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.48
	},
	{
		"id": 728,
		"image": "/assets/Generation-1/728.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.05
	},
	{
		"id": 729,
		"image": "/assets/Generation-1/729.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.6
	},
	{
		"id": 730,
		"image": "/assets/Generation-1/730.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 63.760000000000005
	},
	{
		"id": 731,
		"image": "/assets/Generation-1/731.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 732,
		"image": "/assets/Generation-1/732.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.1
	},
	{
		"id": 733,
		"image": "/assets/Generation-1/733.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 69.24000000000001
	},
	{
		"id": 734,
		"image": "/assets/Generation-1/734.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.63
	},
	{
		"id": 735,
		"image": "/assets/Generation-1/735.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.36
	},
	{
		"id": 736,
		"image": "/assets/Generation-1/736.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 71.7
	},
	{
		"id": 737,
		"image": "/assets/Generation-1/737.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 63.2
	},
	{
		"id": 738,
		"image": "/assets/Generation-1/738.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.52000000000001
	},
	{
		"id": 739,
		"image": "/assets/Generation-1/739.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.49000000000001
	},
	{
		"id": 740,
		"image": "/assets/Generation-1/740.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.52000000000001
	},
	{
		"id": 741,
		"image": "/assets/Generation-1/741.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 75.7
	},
	{
		"id": 742,
		"image": "/assets/Generation-1/742.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 58.830000000000005
	},
	{
		"id": 743,
		"image": "/assets/Generation-1/743.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 72.9
	},
	{
		"id": 744,
		"image": "/assets/Generation-1/744.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 75.80000000000001
	},
	{
		"id": 745,
		"image": "/assets/Generation-1/745.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 60.7
	},
	{
		"id": 746,
		"image": "/assets/Generation-1/746.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 59.05
	},
	{
		"id": 747,
		"image": "/assets/Generation-1/747.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 68.72
	},
	{
		"id": 748,
		"image": "/assets/Generation-1/748.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.63
	},
	{
		"id": 749,
		"image": "/assets/Generation-1/749.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.35
	},
	{
		"id": 750,
		"image": "/assets/Generation-1/750.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.5
	},
	{
		"id": 751,
		"image": "/assets/Generation-1/751.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 752,
		"image": "/assets/Generation-1/752.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.72
	},
	{
		"id": 753,
		"image": "/assets/Generation-1/753.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.69
	},
	{
		"id": 754,
		"image": "/assets/Generation-1/754.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.52000000000001
	},
	{
		"id": 755,
		"image": "/assets/Generation-1/755.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 77.83000000000001
	},
	{
		"id": 756,
		"image": "/assets/Generation-1/756.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 64.72
	},
	{
		"id": 757,
		"image": "/assets/Generation-1/757.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Axolotl Orange", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.27
	},
	{
		"id": 758,
		"image": "/assets/Generation-1/758.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 58.7
	},
	{
		"id": 759,
		"image": "/assets/Generation-1/759.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.65
	},
	{
		"id": 760,
		"image": "/assets/Generation-1/760.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 761,
		"image": "/assets/Generation-1/761.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 67.83000000000001
	},
	{
		"id": 762,
		"image": "/assets/Generation-1/762.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 58.32
	},
	{
		"id": 763,
		"image": "/assets/Generation-1/763.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 59.52
	},
	{
		"id": 764,
		"image": "/assets/Generation-1/764.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.36
	},
	{
		"id": 765,
		"image": "/assets/Generation-1/765.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.49000000000001
	},
	{
		"id": 766,
		"image": "/assets/Generation-1/766.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.72
	},
	{
		"id": 767,
		"image": "/assets/Generation-1/767.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.68
	},
	{
		"id": 768,
		"image": "/assets/Generation-1/768.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 35.92
	},
	{
		"id": 769,
		"image": "/assets/Generation-1/769.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.72
	},
	{
		"id": 770,
		"image": "/assets/Generation-1/770.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.400000000000006
	},
	{
		"id": 771,
		"image": "/assets/Generation-1/771.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 53.6
	},
	{
		"id": 772,
		"image": "/assets/Generation-1/772.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 59.36
	},
	{
		"id": 773,
		"image": "/assets/Generation-1/773.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.42
	},
	{
		"id": 774,
		"image": "/assets/Generation-1/774.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 68.80000000000001
	},
	{
		"id": 775,
		"image": "/assets/Generation-1/775.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.1
	},
	{
		"id": 776,
		"image": "/assets/Generation-1/776.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 777,
		"image": "/assets/Generation-1/777.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.47999999999999
	},
	{
		"id": 778,
		"image": "/assets/Generation-1/778.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.72
	},
	{
		"id": 779,
		"image": "/assets/Generation-1/779.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.65
	},
	{
		"id": 780,
		"image": "/assets/Generation-1/780.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.63
	},
	{
		"id": 781,
		"image": "/assets/Generation-1/781.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 63.25
	},
	{
		"id": 782,
		"image": "/assets/Generation-1/782.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 783,
		"image": "/assets/Generation-1/783.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 784,
		"image": "/assets/Generation-1/784.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 63.56
	},
	{
		"id": 785,
		"image": "/assets/Generation-1/785.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 60.68
	},
	{
		"id": 786,
		"image": "/assets/Generation-1/786.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 63.550000000000004
	},
	{
		"id": 787,
		"image": "/assets/Generation-1/787.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 788,
		"image": "/assets/Generation-1/788.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 55.6
	},
	{
		"id": 789,
		"image": "/assets/Generation-1/789.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.75
	},
	{
		"id": 790,
		"image": "/assets/Generation-1/790.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 62.35
	},
	{
		"id": 791,
		"image": "/assets/Generation-1/791.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.68
	},
	{
		"id": 792,
		"image": "/assets/Generation-1/792.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.68
	},
	{
		"id": 793,
		"image": "/assets/Generation-1/793.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.3
	},
	{
		"id": 794,
		"image": "/assets/Generation-1/794.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.83000000000001
	},
	{
		"id": 795,
		"image": "/assets/Generation-1/795.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.6
	},
	{
		"id": 796,
		"image": "/assets/Generation-1/796.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.68
	},
	{
		"id": 797,
		"image": "/assets/Generation-1/797.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.6
	},
	{
		"id": 798,
		"image": "/assets/Generation-1/798.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 62.49
	},
	{
		"id": 799,
		"image": "/assets/Generation-1/799.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.9
	},
	{
		"id": 800,
		"image": "/assets/Generation-1/800.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.24000000000001
	},
	{
		"id": 801,
		"image": "/assets/Generation-1/801.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 802,
		"image": "/assets/Generation-1/802.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 803,
		"image": "/assets/Generation-1/803.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.04
	},
	{
		"id": 804,
		"image": "/assets/Generation-1/804.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 72.63
	},
	{
		"id": 805,
		"image": "/assets/Generation-1/805.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 806,
		"image": "/assets/Generation-1/806.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 807,
		"image": "/assets/Generation-1/807.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 53.5
	},
	{
		"id": 808,
		"image": "/assets/Generation-1/808.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 54.800000000000004
	},
	{
		"id": 809,
		"image": "/assets/Generation-1/809.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.900000000000006
	},
	{
		"id": 810,
		"image": "/assets/Generation-1/810.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 811,
		"image": "/assets/Generation-1/811.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.56
	},
	{
		"id": 812,
		"image": "/assets/Generation-1/812.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.42
	},
	{
		"id": 813,
		"image": "/assets/Generation-1/813.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 54.36
	},
	{
		"id": 814,
		"image": "/assets/Generation-1/814.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 65.7
	},
	{
		"id": 815,
		"image": "/assets/Generation-1/815.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 57.35
	},
	{
		"id": 816,
		"image": "/assets/Generation-1/816.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 71.92
	},
	{
		"id": 817,
		"image": "/assets/Generation-1/817.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 58.9
	},
	{
		"id": 818,
		"image": "/assets/Generation-1/818.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 58.48
	},
	{
		"id": 819,
		"image": "/assets/Generation-1/819.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 61.9
	},
	{
		"id": 820,
		"image": "/assets/Generation-1/820.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.92
	},
	{
		"id": 821,
		"image": "/assets/Generation-1/821.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.72
	},
	{
		"id": 822,
		"image": "/assets/Generation-1/822.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 56.35
	},
	{
		"id": 823,
		"image": "/assets/Generation-1/823.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 824,
		"image": "/assets/Generation-1/824.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 825,
		"image": "/assets/Generation-1/825.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.05
	},
	{
		"id": 826,
		"image": "/assets/Generation-1/826.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 69.83000000000001
	},
	{
		"id": 827,
		"image": "/assets/Generation-1/827.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.1
	},
	{
		"id": 828,
		"image": "/assets/Generation-1/828.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.72
	},
	{
		"id": 829,
		"image": "/assets/Generation-1/829.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 59.2
	},
	{
		"id": 830,
		"image": "/assets/Generation-1/830.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 64.85
	},
	{
		"id": 831,
		"image": "/assets/Generation-1/831.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 68.05
	},
	{
		"id": 832,
		"image": "/assets/Generation-1/832.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.6
	},
	{
		"id": 833,
		"image": "/assets/Generation-1/833.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 834,
		"image": "/assets/Generation-1/834.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.25
	},
	{
		"id": 835,
		"image": "/assets/Generation-1/835.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 60.650000000000006
	},
	{
		"id": 836,
		"image": "/assets/Generation-1/836.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 69.36
	},
	{
		"id": 837,
		"image": "/assets/Generation-1/837.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.75
	},
	{
		"id": 838,
		"image": "/assets/Generation-1/838.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 58.49
	},
	{
		"id": 839,
		"image": "/assets/Generation-1/839.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 49.75
	},
	{
		"id": 840,
		"image": "/assets/Generation-1/840.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 841,
		"image": "/assets/Generation-1/841.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.25
	},
	{
		"id": 842,
		"image": "/assets/Generation-1/842.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.55000000000001
	},
	{
		"id": 843,
		"image": "/assets/Generation-1/843.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 65.80000000000001
	},
	{
		"id": 844,
		"image": "/assets/Generation-1/844.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.91
	},
	{
		"id": 845,
		"image": "/assets/Generation-1/845.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 846,
		"image": "/assets/Generation-1/846.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.49000000000001
	},
	{
		"id": 847,
		"image": "/assets/Generation-1/847.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 60.45
	},
	{
		"id": 848,
		"image": "/assets/Generation-1/848.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 70.5
	},
	{
		"id": 849,
		"image": "/assets/Generation-1/849.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.52000000000001
	},
	{
		"id": 850,
		"image": "/assets/Generation-1/850.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.63
	},
	{
		"id": 851,
		"image": "/assets/Generation-1/851.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.65
	},
	{
		"id": 852,
		"image": "/assets/Generation-1/852.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.45
	},
	{
		"id": 853,
		"image": "/assets/Generation-1/853.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.47
	},
	{
		"id": 854,
		"image": "/assets/Generation-1/854.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 855,
		"image": "/assets/Generation-1/855.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 856,
		"image": "/assets/Generation-1/856.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 857,
		"image": "/assets/Generation-1/857.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 59.92
	},
	{
		"id": 858,
		"image": "/assets/Generation-1/858.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.32
	},
	{
		"id": 859,
		"image": "/assets/Generation-1/859.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.05
	},
	{
		"id": 860,
		"image": "/assets/Generation-1/860.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 62.5
	},
	{
		"id": 861,
		"image": "/assets/Generation-1/861.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.11
	},
	{
		"id": 862,
		"image": "/assets/Generation-1/862.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.1
	},
	{
		"id": 863,
		"image": "/assets/Generation-1/863.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.39
	},
	{
		"id": 864,
		"image": "/assets/Generation-1/864.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 71.68
	},
	{
		"id": 865,
		"image": "/assets/Generation-1/865.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 64.5
	},
	{
		"id": 866,
		"image": "/assets/Generation-1/866.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 60.72
	},
	{
		"id": 867,
		"image": "/assets/Generation-1/867.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.5
	},
	{
		"id": 868,
		"image": "/assets/Generation-1/868.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.85
	},
	{
		"id": 869,
		"image": "/assets/Generation-1/869.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 69.5
	},
	{
		"id": 870,
		"image": "/assets/Generation-1/870.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 59.65
	},
	{
		"id": 871,
		"image": "/assets/Generation-1/871.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.42
	},
	{
		"id": 872,
		"image": "/assets/Generation-1/872.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.42
	},
	{
		"id": 873,
		"image": "/assets/Generation-1/873.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.05
	},
	{
		"id": 874,
		"image": "/assets/Generation-1/874.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.36
	},
	{
		"id": 875,
		"image": "/assets/Generation-1/875.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.7
	},
	{
		"id": 876,
		"image": "/assets/Generation-1/876.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.4
	},
	{
		"id": 877,
		"image": "/assets/Generation-1/877.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 57.36
	},
	{
		"id": 878,
		"image": "/assets/Generation-1/878.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 78.25
	},
	{
		"id": 879,
		"image": "/assets/Generation-1/879.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 74.9
	},
	{
		"id": 880,
		"image": "/assets/Generation-1/880.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 69.27000000000001
	},
	{
		"id": 881,
		"image": "/assets/Generation-1/881.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 60.36
	},
	{
		"id": 882,
		"image": "/assets/Generation-1/882.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 883,
		"image": "/assets/Generation-1/883.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 884,
		"image": "/assets/Generation-1/884.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 65.62
	},
	{
		"id": 885,
		"image": "/assets/Generation-1/885.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 56.59
	},
	{
		"id": 886,
		"image": "/assets/Generation-1/886.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Orange", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.47
	},
	{
		"id": 887,
		"image": "/assets/Generation-1/887.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 59.410000000000004
	},
	{
		"id": 888,
		"image": "/assets/Generation-1/888.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 46.36
	},
	{
		"id": 889,
		"image": "/assets/Generation-1/889.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 58.550000000000004
	},
	{
		"id": 890,
		"image": "/assets/Generation-1/890.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 68.63
	},
	{
		"id": 891,
		"image": "/assets/Generation-1/891.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 892,
		"image": "/assets/Generation-1/892.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 66.1
	},
	{
		"id": 893,
		"image": "/assets/Generation-1/893.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 894,
		"image": "/assets/Generation-1/894.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.25
	},
	{
		"id": 895,
		"image": "/assets/Generation-1/895.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.62
	},
	{
		"id": 896,
		"image": "/assets/Generation-1/896.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 50.5
	},
	{
		"id": 897,
		"image": "/assets/Generation-1/897.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.75
	},
	{
		"id": 898,
		"image": "/assets/Generation-1/898.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.63
	},
	{
		"id": 899,
		"image": "/assets/Generation-1/899.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 63.36
	},
	{
		"id": 900,
		"image": "/assets/Generation-1/900.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.56
	},
	{
		"id": 901,
		"image": "/assets/Generation-1/901.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.92
	},
	{
		"id": 902,
		"image": "/assets/Generation-1/902.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 66.85
	},
	{
		"id": 903,
		"image": "/assets/Generation-1/903.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 904,
		"image": "/assets/Generation-1/904.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 62.56
	},
	{
		"id": 905,
		"image": "/assets/Generation-1/905.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 48.04
	},
	{
		"id": 906,
		"image": "/assets/Generation-1/906.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.95
	},
	{
		"id": 907,
		"image": "/assets/Generation-1/907.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 62.7
	},
	{
		"id": 908,
		"image": "/assets/Generation-1/908.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 909,
		"image": "/assets/Generation-1/909.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 910,
		"image": "/assets/Generation-1/910.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 58.05
	},
	{
		"id": 911,
		"image": "/assets/Generation-1/911.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 61.63
	},
	{
		"id": 912,
		"image": "/assets/Generation-1/912.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.95
	},
	{
		"id": 913,
		"image": "/assets/Generation-1/913.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 46.5
	},
	{
		"id": 914,
		"image": "/assets/Generation-1/914.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 75.5
	},
	{
		"id": 915,
		"image": "/assets/Generation-1/915.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 72.5
	},
	{
		"id": 916,
		"image": "/assets/Generation-1/916.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 54.92
	},
	{
		"id": 917,
		"image": "/assets/Generation-1/917.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 918,
		"image": "/assets/Generation-1/918.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 919,
		"image": "/assets/Generation-1/919.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.7
	},
	{
		"id": 920,
		"image": "/assets/Generation-1/920.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.36
	},
	{
		"id": 921,
		"image": "/assets/Generation-1/921.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.3
	},
	{
		"id": 922,
		"image": "/assets/Generation-1/922.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 923,
		"image": "/assets/Generation-1/923.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.2
	},
	{
		"id": 924,
		"image": "/assets/Generation-1/924.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.68
	},
	{
		"id": 925,
		"image": "/assets/Generation-1/925.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.2
	},
	{
		"id": 926,
		"image": "/assets/Generation-1/926.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.6
	},
	{
		"id": 927,
		"image": "/assets/Generation-1/927.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.68
	},
	{
		"id": 928,
		"image": "/assets/Generation-1/928.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 69.2
	},
	{
		"id": 929,
		"image": "/assets/Generation-1/929.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 56.690000000000005
	},
	{
		"id": 930,
		"image": "/assets/Generation-1/930.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 931,
		"image": "/assets/Generation-1/931.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.05
	},
	{
		"id": 932,
		"image": "/assets/Generation-1/932.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.56
	},
	{
		"id": 933,
		"image": "/assets/Generation-1/933.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 62.5
	},
	{
		"id": 934,
		"image": "/assets/Generation-1/934.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.830000000000005
	},
	{
		"id": 935,
		"image": "/assets/Generation-1/935.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 61.72
	},
	{
		"id": 936,
		"image": "/assets/Generation-1/936.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.72
	},
	{
		"id": 937,
		"image": "/assets/Generation-1/937.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 938,
		"image": "/assets/Generation-1/938.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 65.95
	},
	{
		"id": 939,
		"image": "/assets/Generation-1/939.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 940,
		"image": "/assets/Generation-1/940.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 70.4
	},
	{
		"id": 941,
		"image": "/assets/Generation-1/941.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.72
	},
	{
		"id": 942,
		"image": "/assets/Generation-1/942.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 60.7
	},
	{
		"id": 943,
		"image": "/assets/Generation-1/943.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.63
	},
	{
		"id": 944,
		"image": "/assets/Generation-1/944.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 60.42
	},
	{
		"id": 945,
		"image": "/assets/Generation-1/945.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 55.42
	},
	{
		"id": 946,
		"image": "/assets/Generation-1/946.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.4
	},
	{
		"id": 947,
		"image": "/assets/Generation-1/947.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.75
	},
	{
		"id": 948,
		"image": "/assets/Generation-1/948.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 74.92
	},
	{
		"id": 949,
		"image": "/assets/Generation-1/949.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.24000000000001
	},
	{
		"id": 950,
		"image": "/assets/Generation-1/950.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 52.72
	},
	{
		"id": 951,
		"image": "/assets/Generation-1/951.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 62.36
	},
	{
		"id": 952,
		"image": "/assets/Generation-1/952.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 953,
		"image": "/assets/Generation-1/953.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.36
	},
	{
		"id": 954,
		"image": "/assets/Generation-1/954.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 63.1
	},
	{
		"id": 955,
		"image": "/assets/Generation-1/955.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 69.91
	},
	{
		"id": 956,
		"image": "/assets/Generation-1/956.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.11
	},
	{
		"id": 957,
		"image": "/assets/Generation-1/957.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 50.3
	},
	{
		"id": 958,
		"image": "/assets/Generation-1/958.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 68.91
	},
	{
		"id": 959,
		"image": "/assets/Generation-1/959.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 62.36
	},
	{
		"id": 960,
		"image": "/assets/Generation-1/960.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.80000000000001
	},
	{
		"id": 961,
		"image": "/assets/Generation-1/961.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.80000000000001
	},
	{
		"id": 962,
		"image": "/assets/Generation-1/962.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 59.59
	},
	{
		"id": 963,
		"image": "/assets/Generation-1/963.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.47999999999999
	},
	{
		"id": 964,
		"image": "/assets/Generation-1/964.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.63
	},
	{
		"id": 965,
		"image": "/assets/Generation-1/965.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.62
	},
	{
		"id": 966,
		"image": "/assets/Generation-1/966.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.92
	},
	{
		"id": 967,
		"image": "/assets/Generation-1/967.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 68.42
	},
	{
		"id": 968,
		"image": "/assets/Generation-1/968.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 70.6
	},
	{
		"id": 969,
		"image": "/assets/Generation-1/969.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.16
	},
	{
		"id": 970,
		"image": "/assets/Generation-1/970.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.80000000000001
	},
	{
		"id": 971,
		"image": "/assets/Generation-1/971.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.92
	},
	{
		"id": 972,
		"image": "/assets/Generation-1/972.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 64.05
	},
	{
		"id": 973,
		"image": "/assets/Generation-1/973.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 60.9
	},
	{
		"id": 974,
		"image": "/assets/Generation-1/974.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.2
	},
	{
		"id": 975,
		"image": "/assets/Generation-1/975.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 976,
		"image": "/assets/Generation-1/976.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.16
	},
	{
		"id": 977,
		"image": "/assets/Generation-1/977.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.32
	},
	{
		"id": 978,
		"image": "/assets/Generation-1/978.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.75
	},
	{
		"id": 979,
		"image": "/assets/Generation-1/979.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 61.56
	},
	{
		"id": 980,
		"image": "/assets/Generation-1/980.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 52.72
	},
	{
		"id": 981,
		"image": "/assets/Generation-1/981.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 74.80000000000001
	},
	{
		"id": 982,
		"image": "/assets/Generation-1/982.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 70.75
	},
	{
		"id": 983,
		"image": "/assets/Generation-1/983.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 984,
		"image": "/assets/Generation-1/984.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 58.650000000000006
	},
	{
		"id": 985,
		"image": "/assets/Generation-1/985.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 54.45
	},
	{
		"id": 986,
		"image": "/assets/Generation-1/986.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.2
	},
	{
		"id": 987,
		"image": "/assets/Generation-1/987.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 75.5
	},
	{
		"id": 988,
		"image": "/assets/Generation-1/988.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.68
	},
	{
		"id": 989,
		"image": "/assets/Generation-1/989.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 76.1
	},
	{
		"id": 990,
		"image": "/assets/Generation-1/990.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.42
	},
	{
		"id": 991,
		"image": "/assets/Generation-1/991.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.68
	},
	{
		"id": 992,
		"image": "/assets/Generation-1/992.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 993,
		"image": "/assets/Generation-1/993.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.9
	},
	{
		"id": 994,
		"image": "/assets/Generation-1/994.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 995,
		"image": "/assets/Generation-1/995.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 72.63
	},
	{
		"id": 996,
		"image": "/assets/Generation-1/996.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.36
	},
	{
		"id": 997,
		"image": "/assets/Generation-1/997.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 998,
		"image": "/assets/Generation-1/998.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 56.92
	},
	{
		"id": 999,
		"image": "/assets/Generation-1/999.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 70.56
	},
	{
		"id": 1000,
		"image": "/assets/Generation-1/1000.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 1001,
		"image": "/assets/Generation-1/1001.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.27000000000001
	},
	{
		"id": 1002,
		"image": "/assets/Generation-1/1002.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 79.11
	},
	{
		"id": 1003,
		"image": "/assets/Generation-1/1003.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 1004,
		"image": "/assets/Generation-1/1004.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 52.42
	},
	{
		"id": 1005,
		"image": "/assets/Generation-1/1005.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.42
	},
	{
		"id": 1006,
		"image": "/assets/Generation-1/1006.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.72
	},
	{
		"id": 1007,
		"image": "/assets/Generation-1/1007.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.42
	},
	{
		"id": 1008,
		"image": "/assets/Generation-1/1008.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 53.35
	},
	{
		"id": 1009,
		"image": "/assets/Generation-1/1009.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 60.830000000000005
	},
	{
		"id": 1010,
		"image": "/assets/Generation-1/1010.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.7
	},
	{
		"id": 1011,
		"image": "/assets/Generation-1/1011.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 57.36
	},
	{
		"id": 1012,
		"image": "/assets/Generation-1/1012.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.47
	},
	{
		"id": 1013,
		"image": "/assets/Generation-1/1013.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 55.05
	},
	{
		"id": 1014,
		"image": "/assets/Generation-1/1014.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 1015,
		"image": "/assets/Generation-1/1015.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 57.6
	},
	{
		"id": 1016,
		"image": "/assets/Generation-1/1016.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.42
	},
	{
		"id": 1017,
		"image": "/assets/Generation-1/1017.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 1018,
		"image": "/assets/Generation-1/1018.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.16
	},
	{
		"id": 1019,
		"image": "/assets/Generation-1/1019.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 1020,
		"image": "/assets/Generation-1/1020.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 64.65
	},
	{
		"id": 1021,
		"image": "/assets/Generation-1/1021.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.6
	},
	{
		"id": 1022,
		"image": "/assets/Generation-1/1022.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.72
	},
	{
		"id": 1023,
		"image": "/assets/Generation-1/1023.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 77.1
	},
	{
		"id": 1024,
		"image": "/assets/Generation-1/1024.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 56.05
	},
	{
		"id": 1025,
		"image": "/assets/Generation-1/1025.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.36
	},
	{
		"id": 1026,
		"image": "/assets/Generation-1/1026.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 1027,
		"image": "/assets/Generation-1/1027.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.16
	},
	{
		"id": 1028,
		"image": "/assets/Generation-1/1028.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 67.25
	},
	{
		"id": 1029,
		"image": "/assets/Generation-1/1029.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.05
	},
	{
		"id": 1030,
		"image": "/assets/Generation-1/1030.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.4
	},
	{
		"id": 1031,
		"image": "/assets/Generation-1/1031.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.5
	},
	{
		"id": 1032,
		"image": "/assets/Generation-1/1032.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.80000000000001
	},
	{
		"id": 1033,
		"image": "/assets/Generation-1/1033.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.72
	},
	{
		"id": 1034,
		"image": "/assets/Generation-1/1034.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.2
	},
	{
		"id": 1035,
		"image": "/assets/Generation-1/1035.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 79.95
	},
	{
		"id": 1036,
		"image": "/assets/Generation-1/1036.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.63
	},
	{
		"id": 1037,
		"image": "/assets/Generation-1/1037.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 52.56
	},
	{
		"id": 1038,
		"image": "/assets/Generation-1/1038.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 56.24
	},
	{
		"id": 1039,
		"image": "/assets/Generation-1/1039.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 60.6
	},
	{
		"id": 1040,
		"image": "/assets/Generation-1/1040.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 60.1
	},
	{
		"id": 1041,
		"image": "/assets/Generation-1/1041.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 71.95
	},
	{
		"id": 1042,
		"image": "/assets/Generation-1/1042.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.56
	},
	{
		"id": 1043,
		"image": "/assets/Generation-1/1043.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.45
	},
	{
		"id": 1044,
		"image": "/assets/Generation-1/1044.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 62.05
	},
	{
		"id": 1045,
		"image": "/assets/Generation-1/1045.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.68
	},
	{
		"id": 1046,
		"image": "/assets/Generation-1/1046.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 66.91
	},
	{
		"id": 1047,
		"image": "/assets/Generation-1/1047.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 1048,
		"image": "/assets/Generation-1/1048.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 1049,
		"image": "/assets/Generation-1/1049.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.9
	},
	{
		"id": 1050,
		"image": "/assets/Generation-1/1050.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.4
	},
	{
		"id": 1051,
		"image": "/assets/Generation-1/1051.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 59.75
	},
	{
		"id": 1052,
		"image": "/assets/Generation-1/1052.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.72
	},
	{
		"id": 1053,
		"image": "/assets/Generation-1/1053.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.830000000000005
	},
	{
		"id": 1054,
		"image": "/assets/Generation-1/1054.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.32
	},
	{
		"id": 1055,
		"image": "/assets/Generation-1/1055.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 66.62
	},
	{
		"id": 1056,
		"image": "/assets/Generation-1/1056.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 55.6
	},
	{
		"id": 1057,
		"image": "/assets/Generation-1/1057.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.05
	},
	{
		"id": 1058,
		"image": "/assets/Generation-1/1058.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.05
	},
	{
		"id": 1059,
		"image": "/assets/Generation-1/1059.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.95
	},
	{
		"id": 1060,
		"image": "/assets/Generation-1/1060.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.6
	},
	{
		"id": 1061,
		"image": "/assets/Generation-1/1061.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.62
	},
	{
		"id": 1062,
		"image": "/assets/Generation-1/1062.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 69.2
	},
	{
		"id": 1063,
		"image": "/assets/Generation-1/1063.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 52.6
	},
	{
		"id": 1064,
		"image": "/assets/Generation-1/1064.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 74.7
	},
	{
		"id": 1065,
		"image": "/assets/Generation-1/1065.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 74.80000000000001
	},
	{
		"id": 1066,
		"image": "/assets/Generation-1/1066.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.1
	},
	{
		"id": 1067,
		"image": "/assets/Generation-1/1067.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 61.470000000000006
	},
	{
		"id": 1068,
		"image": "/assets/Generation-1/1068.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.91
	},
	{
		"id": 1069,
		"image": "/assets/Generation-1/1069.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 72.36
	},
	{
		"id": 1070,
		"image": "/assets/Generation-1/1070.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.7
	},
	{
		"id": 1071,
		"image": "/assets/Generation-1/1071.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.5
	},
	{
		"id": 1072,
		"image": "/assets/Generation-1/1072.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 63.75
	},
	{
		"id": 1073,
		"image": "/assets/Generation-1/1073.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 63.45
	},
	{
		"id": 1074,
		"image": "/assets/Generation-1/1074.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 55.5
	},
	{
		"id": 1075,
		"image": "/assets/Generation-1/1075.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 69.1
	},
	{
		"id": 1076,
		"image": "/assets/Generation-1/1076.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 59.49
	},
	{
		"id": 1077,
		"image": "/assets/Generation-1/1077.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 49.620000000000005
	},
	{
		"id": 1078,
		"image": "/assets/Generation-1/1078.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 1079,
		"image": "/assets/Generation-1/1079.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 1080,
		"image": "/assets/Generation-1/1080.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 58.35
	},
	{
		"id": 1081,
		"image": "/assets/Generation-1/1081.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 1082,
		"image": "/assets/Generation-1/1082.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 65.7
	},
	{
		"id": 1083,
		"image": "/assets/Generation-1/1083.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.63
	},
	{
		"id": 1084,
		"image": "/assets/Generation-1/1084.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.41
	},
	{
		"id": 1085,
		"image": "/assets/Generation-1/1085.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.59
	},
	{
		"id": 1086,
		"image": "/assets/Generation-1/1086.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 60.7
	},
	{
		"id": 1087,
		"image": "/assets/Generation-1/1087.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 50.04
	},
	{
		"id": 1088,
		"image": "/assets/Generation-1/1088.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.69
	},
	{
		"id": 1089,
		"image": "/assets/Generation-1/1089.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 1090,
		"image": "/assets/Generation-1/1090.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 51.35
	},
	{
		"id": 1091,
		"image": "/assets/Generation-1/1091.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Axolotl Orange", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.27000000000001
	},
	{
		"id": 1092,
		"image": "/assets/Generation-1/1092.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.1
	},
	{
		"id": 1093,
		"image": "/assets/Generation-1/1093.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 77.7
	},
	{
		"id": 1094,
		"image": "/assets/Generation-1/1094.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 58.72
	},
	{
		"id": 1095,
		"image": "/assets/Generation-1/1095.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 58.6
	},
	{
		"id": 1096,
		"image": "/assets/Generation-1/1096.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 1097,
		"image": "/assets/Generation-1/1097.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 66.4
	},
	{
		"id": 1098,
		"image": "/assets/Generation-1/1098.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.75
	},
	{
		"id": 1099,
		"image": "/assets/Generation-1/1099.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 69.4
	},
	{
		"id": 1100,
		"image": "/assets/Generation-1/1100.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 1101,
		"image": "/assets/Generation-1/1101.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 62.690000000000005
	},
	{
		"id": 1102,
		"image": "/assets/Generation-1/1102.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 64.83000000000001
	},
	{
		"id": 1103,
		"image": "/assets/Generation-1/1103.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 64.72
	},
	{
		"id": 1104,
		"image": "/assets/Generation-1/1104.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.65
	},
	{
		"id": 1105,
		"image": "/assets/Generation-1/1105.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 69.65
	},
	{
		"id": 1106,
		"image": "/assets/Generation-1/1106.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 72.83000000000001
	},
	{
		"id": 1107,
		"image": "/assets/Generation-1/1107.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 59.7
	},
	{
		"id": 1108,
		"image": "/assets/Generation-1/1108.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 51.36
	},
	{
		"id": 1109,
		"image": "/assets/Generation-1/1109.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 55.35
	},
	{
		"id": 1110,
		"image": "/assets/Generation-1/1110.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.4
	},
	{
		"id": 1111,
		"image": "/assets/Generation-1/1111.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 61.16
	},
	{
		"id": 1112,
		"image": "/assets/Generation-1/1112.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 58.91
	},
	{
		"id": 1113,
		"image": "/assets/Generation-1/1113.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.32
	},
	{
		"id": 1114,
		"image": "/assets/Generation-1/1114.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.92
	},
	{
		"id": 1115,
		"image": "/assets/Generation-1/1115.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.36
	},
	{
		"id": 1116,
		"image": "/assets/Generation-1/1116.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.7
	},
	{
		"id": 1117,
		"image": "/assets/Generation-1/1117.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.72
	},
	{
		"id": 1118,
		"image": "/assets/Generation-1/1118.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.3
	},
	{
		"id": 1119,
		"image": "/assets/Generation-1/1119.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 64.05
	},
	{
		"id": 1120,
		"image": "/assets/Generation-1/1120.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.62
	},
	{
		"id": 1121,
		"image": "/assets/Generation-1/1121.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 47.05
	},
	{
		"id": 1122,
		"image": "/assets/Generation-1/1122.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 60.2
	},
	{
		"id": 1123,
		"image": "/assets/Generation-1/1123.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.32
	},
	{
		"id": 1124,
		"image": "/assets/Generation-1/1124.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.3
	},
	{
		"id": 1125,
		"image": "/assets/Generation-1/1125.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 1126,
		"image": "/assets/Generation-1/1126.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.83000000000001
	},
	{
		"id": 1127,
		"image": "/assets/Generation-1/1127.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.83000000000001
	},
	{
		"id": 1128,
		"image": "/assets/Generation-1/1128.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.63
	},
	{
		"id": 1129,
		"image": "/assets/Generation-1/1129.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 1130,
		"image": "/assets/Generation-1/1130.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 66.24000000000001
	},
	{
		"id": 1131,
		"image": "/assets/Generation-1/1131.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.47
	},
	{
		"id": 1132,
		"image": "/assets/Generation-1/1132.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.47999999999999
	},
	{
		"id": 1133,
		"image": "/assets/Generation-1/1133.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.4
	},
	{
		"id": 1134,
		"image": "/assets/Generation-1/1134.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 57.92
	},
	{
		"id": 1135,
		"image": "/assets/Generation-1/1135.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.11
	},
	{
		"id": 1136,
		"image": "/assets/Generation-1/1136.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.24000000000001
	},
	{
		"id": 1137,
		"image": "/assets/Generation-1/1137.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 60.9
	},
	{
		"id": 1138,
		"image": "/assets/Generation-1/1138.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 38.629999999999995
	},
	{
		"id": 1139,
		"image": "/assets/Generation-1/1139.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.72
	},
	{
		"id": 1140,
		"image": "/assets/Generation-1/1140.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.7
	},
	{
		"id": 1141,
		"image": "/assets/Generation-1/1141.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.91
	},
	{
		"id": 1142,
		"image": "/assets/Generation-1/1142.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 63.63
	},
	{
		"id": 1143,
		"image": "/assets/Generation-1/1143.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 74.80000000000001
	},
	{
		"id": 1144,
		"image": "/assets/Generation-1/1144.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 65.63
	},
	{
		"id": 1145,
		"image": "/assets/Generation-1/1145.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 71.9
	},
	{
		"id": 1146,
		"image": "/assets/Generation-1/1146.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 55.7
	},
	{
		"id": 1147,
		"image": "/assets/Generation-1/1147.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 54.2
	},
	{
		"id": 1148,
		"image": "/assets/Generation-1/1148.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.25
	},
	{
		"id": 1149,
		"image": "/assets/Generation-1/1149.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.55000000000001
	},
	{
		"id": 1150,
		"image": "/assets/Generation-1/1150.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 1151,
		"image": "/assets/Generation-1/1151.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 67.80000000000001
	},
	{
		"id": 1152,
		"image": "/assets/Generation-1/1152.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 1153,
		"image": "/assets/Generation-1/1153.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.63
	},
	{
		"id": 1154,
		"image": "/assets/Generation-1/1154.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 1155,
		"image": "/assets/Generation-1/1155.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 60.2
	},
	{
		"id": 1156,
		"image": "/assets/Generation-1/1156.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 1157,
		"image": "/assets/Generation-1/1157.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 63.52
	},
	{
		"id": 1158,
		"image": "/assets/Generation-1/1158.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.3
	},
	{
		"id": 1159,
		"image": "/assets/Generation-1/1159.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.62
	},
	{
		"id": 1160,
		"image": "/assets/Generation-1/1160.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 66.36
	},
	{
		"id": 1161,
		"image": "/assets/Generation-1/1161.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 61.75
	},
	{
		"id": 1162,
		"image": "/assets/Generation-1/1162.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 53.7
	},
	{
		"id": 1163,
		"image": "/assets/Generation-1/1163.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.2
	},
	{
		"id": 1164,
		"image": "/assets/Generation-1/1164.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 62.2
	},
	{
		"id": 1165,
		"image": "/assets/Generation-1/1165.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 54.7
	},
	{
		"id": 1166,
		"image": "/assets/Generation-1/1166.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 1167,
		"image": "/assets/Generation-1/1167.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.36
	},
	{
		"id": 1168,
		"image": "/assets/Generation-1/1168.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 65.05
	},
	{
		"id": 1169,
		"image": "/assets/Generation-1/1169.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.5
	},
	{
		"id": 1170,
		"image": "/assets/Generation-1/1170.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.5
	},
	{
		"id": 1171,
		"image": "/assets/Generation-1/1171.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 1172,
		"image": "/assets/Generation-1/1172.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 68.92
	},
	{
		"id": 1173,
		"image": "/assets/Generation-1/1173.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 52.9
	},
	{
		"id": 1174,
		"image": "/assets/Generation-1/1174.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.4
	},
	{
		"id": 1175,
		"image": "/assets/Generation-1/1175.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.25
	},
	{
		"id": 1176,
		"image": "/assets/Generation-1/1176.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.9
	},
	{
		"id": 1177,
		"image": "/assets/Generation-1/1177.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 68.36
	},
	{
		"id": 1178,
		"image": "/assets/Generation-1/1178.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 1179,
		"image": "/assets/Generation-1/1179.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 68.4
	},
	{
		"id": 1180,
		"image": "/assets/Generation-1/1180.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.47999999999999
	},
	{
		"id": 1181,
		"image": "/assets/Generation-1/1181.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 1182,
		"image": "/assets/Generation-1/1182.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.92
	},
	{
		"id": 1183,
		"image": "/assets/Generation-1/1183.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 1184,
		"image": "/assets/Generation-1/1184.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.69
	},
	{
		"id": 1185,
		"image": "/assets/Generation-1/1185.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 79.25
	},
	{
		"id": 1186,
		"image": "/assets/Generation-1/1186.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 61.24
	},
	{
		"id": 1187,
		"image": "/assets/Generation-1/1187.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 74.69
	},
	{
		"id": 1188,
		"image": "/assets/Generation-1/1188.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 66.2
	},
	{
		"id": 1189,
		"image": "/assets/Generation-1/1189.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 65.85
	},
	{
		"id": 1190,
		"image": "/assets/Generation-1/1190.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 62.92
	},
	{
		"id": 1191,
		"image": "/assets/Generation-1/1191.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 59.72
	},
	{
		"id": 1192,
		"image": "/assets/Generation-1/1192.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.92
	},
	{
		"id": 1193,
		"image": "/assets/Generation-1/1193.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.36
	},
	{
		"id": 1194,
		"image": "/assets/Generation-1/1194.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 58.36
	},
	{
		"id": 1195,
		"image": "/assets/Generation-1/1195.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 64.4
	},
	{
		"id": 1196,
		"image": "/assets/Generation-1/1196.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.91
	},
	{
		"id": 1197,
		"image": "/assets/Generation-1/1197.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 61.72
	},
	{
		"id": 1198,
		"image": "/assets/Generation-1/1198.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 61.05
	},
	{
		"id": 1199,
		"image": "/assets/Generation-1/1199.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.75
	},
	{
		"id": 1200,
		"image": "/assets/Generation-1/1200.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 66.03999999999999
	},
	{
		"id": 1201,
		"image": "/assets/Generation-1/1201.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 68.5
	},
	{
		"id": 1202,
		"image": "/assets/Generation-1/1202.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sunfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 60.72
	},
	{
		"id": 1203,
		"image": "/assets/Generation-1/1203.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.68
	},
	{
		"id": 1204,
		"image": "/assets/Generation-1/1204.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 74.25
	},
	{
		"id": 1205,
		"image": "/assets/Generation-1/1205.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 63.6
	},
	{
		"id": 1206,
		"image": "/assets/Generation-1/1206.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 1207,
		"image": "/assets/Generation-1/1207.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.36
	},
	{
		"id": 1208,
		"image": "/assets/Generation-1/1208.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.3
	},
	{
		"id": 1209,
		"image": "/assets/Generation-1/1209.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.80000000000001
	},
	{
		"id": 1210,
		"image": "/assets/Generation-1/1210.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.6
	},
	{
		"id": 1211,
		"image": "/assets/Generation-1/1211.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 60.72
	},
	{
		"id": 1212,
		"image": "/assets/Generation-1/1212.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 66.85
	},
	{
		"id": 1213,
		"image": "/assets/Generation-1/1213.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.49000000000001
	},
	{
		"id": 1214,
		"image": "/assets/Generation-1/1214.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.68
	},
	{
		"id": 1215,
		"image": "/assets/Generation-1/1215.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.5
	},
	{
		"id": 1216,
		"image": "/assets/Generation-1/1216.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.6
	},
	{
		"id": 1217,
		"image": "/assets/Generation-1/1217.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.2
	},
	{
		"id": 1218,
		"image": "/assets/Generation-1/1218.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 79.5
	},
	{
		"id": 1219,
		"image": "/assets/Generation-1/1219.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.11
	},
	{
		"id": 1220,
		"image": "/assets/Generation-1/1220.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 69.6
	},
	{
		"id": 1221,
		"image": "/assets/Generation-1/1221.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 56.7
	},
	{
		"id": 1222,
		"image": "/assets/Generation-1/1222.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.85
	},
	{
		"id": 1223,
		"image": "/assets/Generation-1/1223.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 61.7
	},
	{
		"id": 1224,
		"image": "/assets/Generation-1/1224.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 1225,
		"image": "/assets/Generation-1/1225.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.95
	},
	{
		"id": 1226,
		"image": "/assets/Generation-1/1226.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Sunfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 55.72
	},
	{
		"id": 1227,
		"image": "/assets/Generation-1/1227.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.75
	},
	{
		"id": 1228,
		"image": "/assets/Generation-1/1228.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 57.63
	},
	{
		"id": 1229,
		"image": "/assets/Generation-1/1229.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.5
	},
	{
		"id": 1230,
		"image": "/assets/Generation-1/1230.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.42
	},
	{
		"id": 1231,
		"image": "/assets/Generation-1/1231.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.5
	},
	{
		"id": 1232,
		"image": "/assets/Generation-1/1232.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 61.1
	},
	{
		"id": 1233,
		"image": "/assets/Generation-1/1233.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 58.27
	},
	{
		"id": 1234,
		"image": "/assets/Generation-1/1234.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.2
	},
	{
		"id": 1235,
		"image": "/assets/Generation-1/1235.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 63.7
	},
	{
		"id": 1236,
		"image": "/assets/Generation-1/1236.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.1
	},
	{
		"id": 1237,
		"image": "/assets/Generation-1/1237.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 65.41
	},
	{
		"id": 1238,
		"image": "/assets/Generation-1/1238.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 66.11
	},
	{
		"id": 1239,
		"image": "/assets/Generation-1/1239.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 52.56
	},
	{
		"id": 1240,
		"image": "/assets/Generation-1/1240.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 72.5
	},
	{
		"id": 1241,
		"image": "/assets/Generation-1/1241.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.75
	},
	{
		"id": 1242,
		"image": "/assets/Generation-1/1242.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 66.56
	},
	{
		"id": 1243,
		"image": "/assets/Generation-1/1243.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.72
	},
	{
		"id": 1244,
		"image": "/assets/Generation-1/1244.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.35
	},
	{
		"id": 1245,
		"image": "/assets/Generation-1/1245.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.7
	},
	{
		"id": 1246,
		"image": "/assets/Generation-1/1246.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 57.2
	},
	{
		"id": 1247,
		"image": "/assets/Generation-1/1247.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.7
	},
	{
		"id": 1248,
		"image": "/assets/Generation-1/1248.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.3
	},
	{
		"id": 1249,
		"image": "/assets/Generation-1/1249.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 62.2
	},
	{
		"id": 1250,
		"image": "/assets/Generation-1/1250.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 55.56
	},
	{
		"id": 1251,
		"image": "/assets/Generation-1/1251.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.5
	},
	{
		"id": 1252,
		"image": "/assets/Generation-1/1252.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 76.5
	},
	{
		"id": 1253,
		"image": "/assets/Generation-1/1253.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.52000000000001
	},
	{
		"id": 1254,
		"image": "/assets/Generation-1/1254.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 1255,
		"image": "/assets/Generation-1/1255.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.65
	},
	{
		"id": 1256,
		"image": "/assets/Generation-1/1256.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.63
	},
	{
		"id": 1257,
		"image": "/assets/Generation-1/1257.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 75.83000000000001
	},
	{
		"id": 1258,
		"image": "/assets/Generation-1/1258.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.3
	},
	{
		"id": 1259,
		"image": "/assets/Generation-1/1259.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 74.36
	},
	{
		"id": 1260,
		"image": "/assets/Generation-1/1260.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 74.25
	},
	{
		"id": 1261,
		"image": "/assets/Generation-1/1261.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 57.92
	},
	{
		"id": 1262,
		"image": "/assets/Generation-1/1262.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 59.05
	},
	{
		"id": 1263,
		"image": "/assets/Generation-1/1263.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 53.72
	},
	{
		"id": 1264,
		"image": "/assets/Generation-1/1264.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.6
	},
	{
		"id": 1265,
		"image": "/assets/Generation-1/1265.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.32
	},
	{
		"id": 1266,
		"image": "/assets/Generation-1/1266.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.83000000000001
	},
	{
		"id": 1267,
		"image": "/assets/Generation-1/1267.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.1
	},
	{
		"id": 1268,
		"image": "/assets/Generation-1/1268.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 53.85
	},
	{
		"id": 1269,
		"image": "/assets/Generation-1/1269.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 1270,
		"image": "/assets/Generation-1/1270.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.6
	},
	{
		"id": 1271,
		"image": "/assets/Generation-1/1271.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 63.21
	},
	{
		"id": 1272,
		"image": "/assets/Generation-1/1272.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.72
	},
	{
		"id": 1273,
		"image": "/assets/Generation-1/1273.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.9
	},
	{
		"id": 1274,
		"image": "/assets/Generation-1/1274.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.7
	},
	{
		"id": 1275,
		"image": "/assets/Generation-1/1275.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.24
	},
	{
		"id": 1276,
		"image": "/assets/Generation-1/1276.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 64.05
	},
	{
		"id": 1277,
		"image": "/assets/Generation-1/1277.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 55.36
	},
	{
		"id": 1278,
		"image": "/assets/Generation-1/1278.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 72.9
	},
	{
		"id": 1279,
		"image": "/assets/Generation-1/1279.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 61.900000000000006
	},
	{
		"id": 1280,
		"image": "/assets/Generation-1/1280.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 51.2
	},
	{
		"id": 1281,
		"image": "/assets/Generation-1/1281.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.80000000000001
	},
	{
		"id": 1282,
		"image": "/assets/Generation-1/1282.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.92
	},
	{
		"id": 1283,
		"image": "/assets/Generation-1/1283.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Hammerhead Green", "rarity": 0.65 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.65
	},
	{
		"id": 1284,
		"image": "/assets/Generation-1/1284.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.39
	},
	{
		"id": 1285,
		"image": "/assets/Generation-1/1285.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 64.16
	},
	{
		"id": 1286,
		"image": "/assets/Generation-1/1286.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.56
	},
	{
		"id": 1287,
		"image": "/assets/Generation-1/1287.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.5
	},
	{
		"id": 1288,
		"image": "/assets/Generation-1/1288.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.2
	},
	{
		"id": 1289,
		"image": "/assets/Generation-1/1289.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 66.7
	},
	{
		"id": 1290,
		"image": "/assets/Generation-1/1290.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 55.42
	},
	{
		"id": 1291,
		"image": "/assets/Generation-1/1291.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 1292,
		"image": "/assets/Generation-1/1292.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 62.68
	},
	{
		"id": 1293,
		"image": "/assets/Generation-1/1293.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 60.620000000000005
	},
	{
		"id": 1294,
		"image": "/assets/Generation-1/1294.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.7
	},
	{
		"id": 1295,
		"image": "/assets/Generation-1/1295.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 71.2
	},
	{
		"id": 1296,
		"image": "/assets/Generation-1/1296.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.21000000000001
	},
	{
		"id": 1297,
		"image": "/assets/Generation-1/1297.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.9
	},
	{
		"id": 1298,
		"image": "/assets/Generation-1/1298.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Black", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 1299,
		"image": "/assets/Generation-1/1299.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 74.4
	},
	{
		"id": 1300,
		"image": "/assets/Generation-1/1300.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 1301,
		"image": "/assets/Generation-1/1301.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 60.7
	},
	{
		"id": 1302,
		"image": "/assets/Generation-1/1302.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 71.5
	},
	{
		"id": 1303,
		"image": "/assets/Generation-1/1303.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 63.35
	},
	{
		"id": 1304,
		"image": "/assets/Generation-1/1304.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.42
	},
	{
		"id": 1305,
		"image": "/assets/Generation-1/1305.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 64.80000000000001
	},
	{
		"id": 1306,
		"image": "/assets/Generation-1/1306.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 61.56
	},
	{
		"id": 1307,
		"image": "/assets/Generation-1/1307.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 64.6
	},
	{
		"id": 1308,
		"image": "/assets/Generation-1/1308.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 1309,
		"image": "/assets/Generation-1/1309.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 69.83000000000001
	},
	{
		"id": 1310,
		"image": "/assets/Generation-1/1310.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 64.9
	},
	{
		"id": 1311,
		"image": "/assets/Generation-1/1311.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 80.1
	},
	{
		"id": 1312,
		"image": "/assets/Generation-1/1312.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 70.72
	},
	{
		"id": 1313,
		"image": "/assets/Generation-1/1313.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 72.95
	},
	{
		"id": 1314,
		"image": "/assets/Generation-1/1314.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 56.620000000000005
	},
	{
		"id": 1315,
		"image": "/assets/Generation-1/1315.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 60.25
	},
	{
		"id": 1316,
		"image": "/assets/Generation-1/1316.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.47999999999999
	},
	{
		"id": 1317,
		"image": "/assets/Generation-1/1317.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 73.11
	},
	{
		"id": 1318,
		"image": "/assets/Generation-1/1318.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 67.62
	},
	{
		"id": 1319,
		"image": "/assets/Generation-1/1319.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 64.24000000000001
	},
	{
		"id": 1320,
		"image": "/assets/Generation-1/1320.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.36
	},
	{
		"id": 1321,
		"image": "/assets/Generation-1/1321.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 66.25
	},
	{
		"id": 1322,
		"image": "/assets/Generation-1/1322.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.36
	},
	{
		"id": 1323,
		"image": "/assets/Generation-1/1323.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Hammerhead Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.75
	},
	{
		"id": 1324,
		"image": "/assets/Generation-1/1324.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 57.3
	},
	{
		"id": 1325,
		"image": "/assets/Generation-1/1325.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 58.63
	},
	{
		"id": 1326,
		"image": "/assets/Generation-1/1326.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Cosmic", "rarity": 0.32 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.52000000000001
	},
	{
		"id": 1327,
		"image": "/assets/Generation-1/1327.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 61.6
	},
	{
		"id": 1328,
		"image": "/assets/Generation-1/1328.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 74.76
	},
	{
		"id": 1329,
		"image": "/assets/Generation-1/1329.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 1330,
		"image": "/assets/Generation-1/1330.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 58.410000000000004
	},
	{
		"id": 1331,
		"image": "/assets/Generation-1/1331.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Orca Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 64.36
	},
	{
		"id": 1332,
		"image": "/assets/Generation-1/1332.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 63.400000000000006
	},
	{
		"id": 1333,
		"image": "/assets/Generation-1/1333.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 64.3
	},
	{
		"id": 1334,
		"image": "/assets/Generation-1/1334.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.75
	},
	{
		"id": 1335,
		"image": "/assets/Generation-1/1335.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 53.25
	},
	{
		"id": 1336,
		"image": "/assets/Generation-1/1336.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 1337,
		"image": "/assets/Generation-1/1337.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.92
	},
	{
		"id": 1338,
		"image": "/assets/Generation-1/1338.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 1339,
		"image": "/assets/Generation-1/1339.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.800000000000004
	},
	{
		"id": 1340,
		"image": "/assets/Generation-1/1340.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 1341,
		"image": "/assets/Generation-1/1341.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 56.16
	},
	{
		"id": 1342,
		"image": "/assets/Generation-1/1342.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 66.63
	},
	{
		"id": 1343,
		"image": "/assets/Generation-1/1343.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 71.92
	},
	{
		"id": 1344,
		"image": "/assets/Generation-1/1344.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 69.95
	},
	{
		"id": 1345,
		"image": "/assets/Generation-1/1345.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.36
	},
	{
		"id": 1346,
		"image": "/assets/Generation-1/1346.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 75.95
	},
	{
		"id": 1347,
		"image": "/assets/Generation-1/1347.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 65.62
	},
	{
		"id": 1348,
		"image": "/assets/Generation-1/1348.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 60.42
	},
	{
		"id": 1349,
		"image": "/assets/Generation-1/1349.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 60.92
	},
	{
		"id": 1350,
		"image": "/assets/Generation-1/1350.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.05
	},
	{
		"id": 1351,
		"image": "/assets/Generation-1/1351.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 74.11
	},
	{
		"id": 1352,
		"image": "/assets/Generation-1/1352.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.16
	},
	{
		"id": 1353,
		"image": "/assets/Generation-1/1353.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 63.75
	},
	{
		"id": 1354,
		"image": "/assets/Generation-1/1354.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 70.95
	},
	{
		"id": 1355,
		"image": "/assets/Generation-1/1355.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 70.24000000000001
	},
	{
		"id": 1356,
		"image": "/assets/Generation-1/1356.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 1357,
		"image": "/assets/Generation-1/1357.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 55.42
	},
	{
		"id": 1358,
		"image": "/assets/Generation-1/1358.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.35
	},
	{
		"id": 1359,
		"image": "/assets/Generation-1/1359.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 72.47999999999999
	},
	{
		"id": 1360,
		"image": "/assets/Generation-1/1360.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 60.91
	},
	{
		"id": 1361,
		"image": "/assets/Generation-1/1361.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 62.05
	},
	{
		"id": 1362,
		"image": "/assets/Generation-1/1362.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 62.63
	},
	{
		"id": 1363,
		"image": "/assets/Generation-1/1363.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 1364,
		"image": "/assets/Generation-1/1364.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.92
	},
	{
		"id": 1365,
		"image": "/assets/Generation-1/1365.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.1
	},
	{
		"id": 1366,
		"image": "/assets/Generation-1/1366.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 68.63
	},
	{
		"id": 1367,
		"image": "/assets/Generation-1/1367.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 54.5
	},
	{
		"id": 1368,
		"image": "/assets/Generation-1/1368.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.80000000000001
	},
	{
		"id": 1369,
		"image": "/assets/Generation-1/1369.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 62.92
	},
	{
		"id": 1370,
		"image": "/assets/Generation-1/1370.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sea Turtle Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 70.11
	},
	{
		"id": 1371,
		"image": "/assets/Generation-1/1371.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Sea Turtles", "rarity": 2 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 52.48
	},
	{
		"id": 1372,
		"image": "/assets/Generation-1/1372.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.5
	},
	{
		"id": 1373,
		"image": "/assets/Generation-1/1373.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.3
	},
	{
		"id": 1374,
		"image": "/assets/Generation-1/1374.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.55000000000001
	},
	{
		"id": 1375,
		"image": "/assets/Generation-1/1375.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.9
	},
	{
		"id": 1376,
		"image": "/assets/Generation-1/1376.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.42
	},
	{
		"id": 1377,
		"image": "/assets/Generation-1/1377.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Narwhal Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.25
	},
	{
		"id": 1378,
		"image": "/assets/Generation-1/1378.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 72.72
	},
	{
		"id": 1379,
		"image": "/assets/Generation-1/1379.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 1380,
		"image": "/assets/Generation-1/1380.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.25
	},
	{
		"id": 1381,
		"image": "/assets/Generation-1/1381.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 64.2
	},
	{
		"id": 1382,
		"image": "/assets/Generation-1/1382.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 1383,
		"image": "/assets/Generation-1/1383.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.62
	},
	{
		"id": 1384,
		"image": "/assets/Generation-1/1384.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 70.24000000000001
	},
	{
		"id": 1385,
		"image": "/assets/Generation-1/1385.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Orange", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 73.1
	},
	{
		"id": 1386,
		"image": "/assets/Generation-1/1386.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 1387,
		"image": "/assets/Generation-1/1387.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.5
	},
	{
		"id": 1388,
		"image": "/assets/Generation-1/1388.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Dolphin Grey", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 59.75
	},
	{
		"id": 1389,
		"image": "/assets/Generation-1/1389.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.25
	},
	{
		"id": 1390,
		"image": "/assets/Generation-1/1390.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 67.72
	},
	{
		"id": 1391,
		"image": "/assets/Generation-1/1391.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 62.16
	},
	{
		"id": 1392,
		"image": "/assets/Generation-1/1392.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.3
	},
	{
		"id": 1393,
		"image": "/assets/Generation-1/1393.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{
				"layer": "Creature",
				"variation": "Sea Turtle Rainbow",
				"rarity": 0.42
			},
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.42
	},
	{
		"id": 1394,
		"image": "/assets/Generation-1/1394.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.63
	},
	{
		"id": 1395,
		"image": "/assets/Generation-1/1395.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.92
	},
	{
		"id": 1396,
		"image": "/assets/Generation-1/1396.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Red", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.83000000000001
	},
	{
		"id": 1397,
		"image": "/assets/Generation-1/1397.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 75.5
	},
	{
		"id": 1398,
		"image": "/assets/Generation-1/1398.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 77.7
	},
	{
		"id": 1399,
		"image": "/assets/Generation-1/1399.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.83000000000001
	},
	{
		"id": 1400,
		"image": "/assets/Generation-1/1400.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 54.42
	},
	{
		"id": 1401,
		"image": "/assets/Generation-1/1401.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.80000000000001
	},
	{
		"id": 1402,
		"image": "/assets/Generation-1/1402.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Seahorse Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 70.7
	},
	{
		"id": 1403,
		"image": "/assets/Generation-1/1403.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 65.83000000000001
	},
	{
		"id": 1404,
		"image": "/assets/Generation-1/1404.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 72.1
	},
	{
		"id": 1405,
		"image": "/assets/Generation-1/1405.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 70.24000000000001
	},
	{
		"id": 1406,
		"image": "/assets/Generation-1/1406.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "None", "rarity": 5 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 45.5
	},
	{
		"id": 1407,
		"image": "/assets/Generation-1/1407.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Red", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 69.1
	},
	{
		"id": 1408,
		"image": "/assets/Generation-1/1408.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 66.6
	},
	{
		"id": 1409,
		"image": "/assets/Generation-1/1409.png",
		"properties": [
			{ "layer": "Water", "variation": "Oil Spill", "rarity": 4 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Purple", "rarity": 0.35 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 65.55000000000001
	},
	{
		"id": 1410,
		"image": "/assets/Generation-1/1410.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sunfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 67.4
	},
	{
		"id": 1411,
		"image": "/assets/Generation-1/1411.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 56.7
	},
	{
		"id": 1412,
		"image": "/assets/Generation-1/1412.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.68
	},
	{
		"id": 1413,
		"image": "/assets/Generation-1/1413.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 66.42
	},
	{
		"id": 1414,
		"image": "/assets/Generation-1/1414.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 66.80000000000001
	},
	{
		"id": 1415,
		"image": "/assets/Generation-1/1415.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 67.47999999999999
	},
	{
		"id": 1416,
		"image": "/assets/Generation-1/1416.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 59.72
	},
	{
		"id": 1417,
		"image": "/assets/Generation-1/1417.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 68.4
	},
	{
		"id": 1418,
		"image": "/assets/Generation-1/1418.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 60.42
	},
	{
		"id": 1419,
		"image": "/assets/Generation-1/1419.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Swordfish Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 72.92
	},
	{
		"id": 1420,
		"image": "/assets/Generation-1/1420.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.2
	},
	{
		"id": 1421,
		"image": "/assets/Generation-1/1421.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 64.9
	},
	{
		"id": 1422,
		"image": "/assets/Generation-1/1422.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 77.4
	},
	{
		"id": 1423,
		"image": "/assets/Generation-1/1423.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Narwhal Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 55.42
	},
	{
		"id": 1424,
		"image": "/assets/Generation-1/1424.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.92
	},
	{
		"id": 1425,
		"image": "/assets/Generation-1/1425.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 65.92
	},
	{
		"id": 1426,
		"image": "/assets/Generation-1/1426.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.6
	},
	{
		"id": 1427,
		"image": "/assets/Generation-1/1427.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sea Turtle Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 76.05
	},
	{
		"id": 1428,
		"image": "/assets/Generation-1/1428.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 68.6
	},
	{
		"id": 1429,
		"image": "/assets/Generation-1/1429.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Narwhal Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 58.05
	},
	{
		"id": 1430,
		"image": "/assets/Generation-1/1430.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 1431,
		"image": "/assets/Generation-1/1431.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Ribbon Eel Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 65.36
	},
	{
		"id": 1432,
		"image": "/assets/Generation-1/1432.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 1433,
		"image": "/assets/Generation-1/1433.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 1434,
		"image": "/assets/Generation-1/1434.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Grey", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 67.25
	},
	{
		"id": 1435,
		"image": "/assets/Generation-1/1435.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Orca Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 65.6
	},
	{
		"id": 1436,
		"image": "/assets/Generation-1/1436.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 66.68
	},
	{
		"id": 1437,
		"image": "/assets/Generation-1/1437.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Red", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 60.36
	},
	{
		"id": 1438,
		"image": "/assets/Generation-1/1438.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 72.47999999999999
	},
	{
		"id": 1439,
		"image": "/assets/Generation-1/1439.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Pink", "rarity": 0.63 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 60.63
	},
	{
		"id": 1440,
		"image": "/assets/Generation-1/1440.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Ribbon Eel Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{
				"layer": "Shell",
				"variation": "Sphaerocypraea Incomparabilis",
				"rarity": 3
			}
		],
		"overallRarity": 53.6
	},
	{
		"id": 1441,
		"image": "/assets/Generation-1/1441.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 66.24000000000001
	},
	{
		"id": 1442,
		"image": "/assets/Generation-1/1442.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 66.5
	},
	{
		"id": 1443,
		"image": "/assets/Generation-1/1443.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.25
	},
	{
		"id": 1444,
		"image": "/assets/Generation-1/1444.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 71.47999999999999
	},
	{
		"id": 1445,
		"image": "/assets/Generation-1/1445.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 65.9
	},
	{
		"id": 1446,
		"image": "/assets/Generation-1/1446.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Algae", "rarity": 5 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 65.5
	},
	{
		"id": 1447,
		"image": "/assets/Generation-1/1447.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Sea Turtle Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 76.25
	},
	{
		"id": 1448,
		"image": "/assets/Generation-1/1448.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Mahi Mahi Black", "rarity": 0.49 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 79.69
	},
	{
		"id": 1449,
		"image": "/assets/Generation-1/1449.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 76.7
	},
	{
		"id": 1450,
		"image": "/assets/Generation-1/1450.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Feather Star", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 64.2
	},
	{
		"id": 1451,
		"image": "/assets/Generation-1/1451.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Blue Whale Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 76.36
	},
	{
		"id": 1452,
		"image": "/assets/Generation-1/1452.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Orca Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 63.56
	},
	{
		"id": 1453,
		"image": "/assets/Generation-1/1453.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Octopus Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 68.7
	},
	{
		"id": 1454,
		"image": "/assets/Generation-1/1454.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 69.24000000000001
	},
	{
		"id": 1455,
		"image": "/assets/Generation-1/1455.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.2
	},
	{
		"id": 1456,
		"image": "/assets/Generation-1/1456.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Octopus Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 57.5
	},
	{
		"id": 1457,
		"image": "/assets/Generation-1/1457.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.75
	},
	{
		"id": 1458,
		"image": "/assets/Generation-1/1458.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Hammerhead Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 74.65
	},
	{
		"id": 1459,
		"image": "/assets/Generation-1/1459.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 62.7
	},
	{
		"id": 1460,
		"image": "/assets/Generation-1/1460.png",
		"properties": [
			{ "layer": "Water", "variation": "Rainbow", "rarity": 3 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.92
	},
	{
		"id": 1461,
		"image": "/assets/Generation-1/1461.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Plume De Mer", "rarity": 3 },
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 56.9
	},
	{
		"id": 1462,
		"image": "/assets/Generation-1/1462.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "None", "rarity": 4 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sunfish Black", "rarity": 0.56 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 67.56
	},
	{
		"id": 1463,
		"image": "/assets/Generation-1/1463.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 71.7
	},
	{
		"id": 1464,
		"image": "/assets/Generation-1/1464.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Jellyfish Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Lifeless", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.6
	},
	{
		"id": 1465,
		"image": "/assets/Generation-1/1465.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Bioluminescent", "rarity": 4 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.800000000000004
	},
	{
		"id": 1466,
		"image": "/assets/Generation-1/1466.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 71.3
	},
	{
		"id": 1467,
		"image": "/assets/Generation-1/1467.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 70.42
	},
	{
		"id": 1468,
		"image": "/assets/Generation-1/1468.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 53.42
	},
	{
		"id": 1469,
		"image": "/assets/Generation-1/1469.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Shell", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 62.42
	},
	{
		"id": 1470,
		"image": "/assets/Generation-1/1470.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "None", "rarity": 3 },
			{ "layer": "Creature", "variation": "Sea Turtle Golden", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Sponge Coral", "rarity": 5 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 53.42
	},
	{
		"id": 1471,
		"image": "/assets/Generation-1/1471.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Ribbon Eel Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 76.36
	},
	{
		"id": 1472,
		"image": "/assets/Generation-1/1472.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Sunfish Grey", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 60.2
	},
	{
		"id": 1473,
		"image": "/assets/Generation-1/1473.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Yellowfin Tuna", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Manta Ray Orange", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 70.92
	},
	{
		"id": 1474,
		"image": "/assets/Generation-1/1474.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 69.6
	},
	{
		"id": 1475,
		"image": "/assets/Generation-1/1475.png",
		"properties": [
			{ "layer": "Water", "variation": "Swamp", "rarity": 6 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Black", "rarity": 0.21 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 67.41
	},
	{
		"id": 1476,
		"image": "/assets/Generation-1/1476.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.95
	},
	{
		"id": 1477,
		"image": "/assets/Generation-1/1477.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 69.6
	},
	{
		"id": 1478,
		"image": "/assets/Generation-1/1478.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Pink Algae", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Golden", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 73.80000000000001
	},
	{
		"id": 1479,
		"image": "/assets/Generation-1/1479.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Mahi Mahi Blue", "rarity": 1.05 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 66.05
	},
	{
		"id": 1480,
		"image": "/assets/Generation-1/1480.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Narwhal Rainbow", "rarity": 0.42 },
			{ "layer": "Coral", "variation": "Indo Gold", "rarity": 5 },
			{ "layer": "Shell", "variation": "Strawberry", "rarity": 12 }
		],
		"overallRarity": 74.42
	},
	{
		"id": 1481,
		"image": "/assets/Generation-1/1481.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seagrass", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Swordfish Blue", "rarity": 1.2 },
			{ "layer": "Coral", "variation": "Tube Worm", "rarity": 7 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 71.4
	},
	{
		"id": 1482,
		"image": "/assets/Generation-1/1482.png",
		"properties": [
			{ "layer": "Water", "variation": "Crystal Clear", "rarity": 9 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Rainbow Runner", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Table Top", "rarity": 8 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.7
	},
	{
		"id": 1483,
		"image": "/assets/Generation-1/1483.png",
		"properties": [
			{ "layer": "Water", "variation": "Deep Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Axolotl Red", "rarity": 0.27 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 75.47
	},
	{
		"id": 1484,
		"image": "/assets/Generation-1/1484.png",
		"properties": [
			{ "layer": "Water", "variation": "Rose Beam", "rarity": 6 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Cryptotethya Crypta",
				"rarity": 9.2
			},
			{ "layer": "Creature", "variation": "Octopus Pink", "rarity": 0.9 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Miyoko Murex", "rarity": 7 }
		],
		"overallRarity": 74.1
	},
	{
		"id": 1485,
		"image": "/assets/Generation-1/1485.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Schoolmaster Snapper",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Jellyfish Purple", "rarity": 0.7 },
			{ "layer": "Coral", "variation": "Mushroom Leather", "rarity": 7 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 61.7
	},
	{
		"id": 1486,
		"image": "/assets/Generation-1/1486.png",
		"properties": [
			{ "layer": "Water", "variation": "Grenwick", "rarity": 7 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Night", "rarity": 19 },
			{ "layer": "Background", "variation": "Squid", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Neptune Grass", "rarity": 3 },
			{ "layer": "Creature", "variation": "Orca Cosmic", "rarity": 0.16 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 61.16
	},
	{
		"id": 1487,
		"image": "/assets/Generation-1/1487.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Glass", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Anglerfish", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Swordfish Pink", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 55.72
	},
	{
		"id": 1488,
		"image": "/assets/Generation-1/1488.png",
		"properties": [
			{ "layer": "Water", "variation": "Bloody Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Purple", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{
				"layer": "Vegetation",
				"variation": "Acetabularia Acetabulum",
				"rarity": 5
			},
			{ "layer": "Creature", "variation": "Blue Whale Grey", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Cockle", "rarity": 10 }
		],
		"overallRarity": 67.6
	},
	{
		"id": 1489,
		"image": "/assets/Generation-1/1489.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "Pink", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{ "layer": "Background", "variation": "Atlantic Stingray", "rarity": 5 },
			{ "layer": "Vegetation", "variation": "Red Whip", "rarity": 5 },
			{ "layer": "Creature", "variation": "Manta Ray Rainbow", "rarity": 0.48 },
			{ "layer": "Coral", "variation": "Gold Tip", "rarity": 2 },
			{ "layer": "Shell", "variation": "Sand Dollar", "rarity": 12 }
		],
		"overallRarity": 61.48
	},
	{
		"id": 1490,
		"image": "/assets/Generation-1/1490.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Purple", "rarity": 9 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Lookdown", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Blue Mushroom", "rarity": 2 },
			{ "layer": "Creature", "variation": "Narwhal Green", "rarity": 0.91 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Venus Comb Murex", "rarity": 5 }
		],
		"overallRarity": 63.91
	},
	{
		"id": 1491,
		"image": "/assets/Generation-1/1491.png",
		"properties": [
			{ "layer": "Water", "variation": "Gold Ray", "rarity": 5 },
			{ "layer": "Sand", "variation": "Orange", "rarity": 9 },
			{ "layer": "Time", "variation": "Midday", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Axolotl Green", "rarity": 0.39 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Turban", "rarity": 10 }
		],
		"overallRarity": 64.39
	},
	{
		"id": 1492,
		"image": "/assets/Generation-1/1492.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Wahoo", "rarity": 3 },
			{ "layer": "Vegetation", "variation": "Seaweed", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Dolphin Red", "rarity": 0.45 },
			{ "layer": "Coral", "variation": "None", "rarity": 5 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 59.650000000000006
	},
	{
		"id": 1493,
		"image": "/assets/Generation-1/1493.png",
		"properties": [
			{ "layer": "Water", "variation": "Hot Aquamarine", "rarity": 9 },
			{ "layer": "Sand", "variation": "Green", "rarity": 9 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Blue Whale Pink", "rarity": 0.36 },
			{ "layer": "Coral", "variation": "Kenya Tree", "rarity": 3 },
			{ "layer": "Shell", "variation": "None", "rarity": 7 }
		],
		"overallRarity": 63.36
	},
	{
		"id": 1494,
		"image": "/assets/Generation-1/1494.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{ "layer": "Background", "variation": "Red Snapper", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Seahorse Blue", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Elkhorn", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 73.7
	},
	{
		"id": 1495,
		"image": "/assets/Generation-1/1495.png",
		"properties": [
			{ "layer": "Water", "variation": "Daydream", "rarity": 7 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Blueback Herring", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Green", "rarity": 1.3 },
			{ "layer": "Coral", "variation": "Acropora", "rarity": 8 },
			{ "layer": "Shell", "variation": "Starfish", "rarity": 10 }
		],
		"overallRarity": 68.3
	},
	{
		"id": 1496,
		"image": "/assets/Generation-1/1496.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Pufferfish", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Seahorse Rainbow", "rarity": 0.6 },
			{ "layer": "Coral", "variation": "Anemone", "rarity": 8 },
			{ "layer": "Shell", "variation": "Conus Gloriamaris", "rarity": 7 }
		],
		"overallRarity": 65.6
	},
	{
		"id": 1497,
		"image": "/assets/Generation-1/1497.png",
		"properties": [
			{ "layer": "Water", "variation": "Odyssey", "rarity": 6 },
			{ "layer": "Sand", "variation": "Black", "rarity": 7 },
			{ "layer": "Time", "variation": "Morning", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Posidonia", "rarity": 7 },
			{ "layer": "Creature", "variation": "Dolphin Blue", "rarity": 0.75 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "Nautilus", "rarity": 7 }
		],
		"overallRarity": 62.75
	},
	{
		"id": 1498,
		"image": "/assets/Generation-1/1498.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "White", "rarity": 11 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{ "layer": "Background", "variation": "Bay Anchovies", "rarity": 9 },
			{ "layer": "Vegetation", "variation": "Sea Worm", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Red", "rarity": 0.72 },
			{ "layer": "Coral", "variation": "Tube Coral", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 65.72
	},
	{
		"id": 1499,
		"image": "/assets/Generation-1/1499.png",
		"properties": [
			{ "layer": "Water", "variation": "Pink Fleur", "rarity": 7 },
			{ "layer": "Sand", "variation": "Rocky", "rarity": 15 },
			{ "layer": "Time", "variation": "Afternoon", "rarity": 19 },
			{
				"layer": "Background",
				"variation": "Silver Jenny Mojarra",
				"rarity": 9
			},
			{ "layer": "Vegetation", "variation": "Sea Plume", "rarity": 7 },
			{ "layer": "Creature", "variation": "Manta Ray Green", "rarity": 1.04 },
			{ "layer": "Coral", "variation": "Brain", "rarity": 8 },
			{ "layer": "Shell", "variation": "Queen Conch", "rarity": 5 }
		],
		"overallRarity": 71.03999999999999
	},
	{
		"id": 1500,
		"image": "/assets/Generation-1/1500.png",
		"properties": [
			{ "layer": "Water", "variation": "Cyan Ray", "rarity": 8 },
			{ "layer": "Sand", "variation": "Red", "rarity": 11 },
			{ "layer": "Time", "variation": "Sunrise", "rarity": 19 },
			{ "layer": "Background", "variation": "Great Barracuda", "rarity": 7 },
			{ "layer": "Vegetation", "variation": "Kelp", "rarity": 9.2 },
			{ "layer": "Creature", "variation": "Jellyfish Grey", "rarity": 1.5 },
			{ "layer": "Coral", "variation": "Dendrophyllia", "rarity": 7 },
			{ "layer": "Shell", "variation": "New Zealand Abalone", "rarity": 5 }
		],
		"overallRarity": 67.7
	}
]
