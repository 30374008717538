import { Property } from './Main';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: white;
`;

const FilterPill = styled.div`
	background-color: black;
	border-radius: 1rem;
	padding: 0.4rem;
	font-size: 0.75rem;
	margin: 0 0.25rem;

	span {
		margin: 0 0.25rem;
		&:first-child {
		}
		&:second-child {
		}
	}
`;

const ActiveFilters = ({ filters, onClick, totalResults }: any) => {
	return (
		<Wrapper>
			{totalResults < 1500 ? `Found ${totalResults} for:` : ''}
			{filters.map((filter: Property, index: any) => {
				return (
					<FilterPill key={index} onClick={() => onClick(filter)}>
						<span>{filter.variation}</span>
						<span>
							<FontAwesomeIcon icon={solid('times')} />
						</span>
					</FilterPill>
				);
			})}
		</Wrapper>
	);
};

export default ActiveFilters;
