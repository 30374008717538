import * as React from 'react';
import styled from 'styled-components';

interface TogglerProps {
	label: string;
	values: string[];
	activeValue: string;
	onClick: any;
}

const Wrapper = styled.div`
	text-align: center;
`;

const Label = styled.span``;

const Options = styled.div`
	color: black;
	background-color: white;
	border-radius: 1rem;
	margin-top: 0.25rem;
	border: 2px solid floralwhite;

	span {
		padding: 0 0.75rem;
		border-radius: 1rem;
	}

	span.active {
		background-color: green;
		color: white;
		border: 1px solid black;
		transition: background-color 1s linear 0, border 1s linear 0;
	}
`;

const Toggler: React.FC<TogglerProps> = ({
	label,
	values,
	onClick,
	activeValue,
}) => {
	if (values.length < 1) return null;

	const [optionA, optionB] = values;

	const toggleValues = () => {
		// find non-active value
		const newActive = activeValue === optionA ? optionB : optionA;
		// make active
		onClick(newActive);
	};

	return (
		<Wrapper>
			<Label>{label}</Label>
			<Options onClick={() => toggleValues()}>
				<span className={optionA === activeValue ? 'active' : ''}>
					{optionA.charAt(0).toUpperCase() + optionA.slice(1)}
				</span>
				<span className={optionB === activeValue ? 'active' : ''}>
					{optionB}
				</span>
			</Options>
		</Wrapper>
	);
};

export default Toggler;
