import styled from 'styled-components';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Head = styled.header`
	color: white;
	margin: 1.25rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media only screen and (min-width: 768px) {
		flex-direction: row;
	}
`;

const Logo = styled.img`
	width: 250px;
	vertical-align: bottom;
	padding: 0.5rem 0;

	&a {
		height: 45px;
	}
`;

const SocialNav = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		font-size: 1rem;
		color: white;
		padding: 0.5rem;
	}
`;

const Header = () => {
	return (
		<Head>
			<a href='https://www.ripplereefs.com'>
				<Logo src='/assets/images/ripplereefs-white-text-logo.svg'></Logo>
			</a>
			<SocialNav>
				<a href='https://www.ripplereefs.com'>
					<FontAwesomeIcon icon={solid('home')}></FontAwesomeIcon>
				</a>
				<a href='https://twitter.com/ripplereefs?lang=en'>
					<FontAwesomeIcon icon={brands('twitter')}></FontAwesomeIcon>
				</a>
				<a href='https://discord.gg/3NysZHZd'>
					<FontAwesomeIcon icon={brands('discord')}></FontAwesomeIcon>
				</a>
			</SocialNav>
		</Head>
	);
};

export default Header;
