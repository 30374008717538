import React, { Component } from 'react';
import styled from 'styled-components';
import { layers } from '../data/layers';
import { collectionOne } from '../data/collection';
import NFT from './NFT';
import LayerSelect from './LayerSelect';
import ActiveFilters from './ActiveFilters';
import Toggler from './Toggler';
// import ReefModal from './Modal';
interface NFTdata {
	id: number;
	image: string;
	properties: Property[];
	overallRarity: number | string;
}
export interface Property {
	layer: string;
	variation: string;
	rarity: number | string;
}
interface MyState {
	layers: object;
	filters: Property[];
	results: NFTdata[];
	sortBy: string;
	filterComparison: string;
	modal: null | NFTdata;
	showModal: boolean;
}

const MainContent = styled.div``;

const FilterMenu = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Layers = styled.div`
	margin: 0 1.25rem;

	.dropdown {
		border-radius: 1rem;
	}

	@media only screen and (min-width: 1015px) {
		> div {
			.dropdown {
				margin: 0;
				border-radius: revert;
			}

			:first-child {
				.dropdown {
					border-bottom-left-radius: 1rem;
					border-top-left-radius: 1rem;
				}
			}
			:last-child {
				.dropdown {
					border-bottom-right-radius: 1rem;
					border-top-right-radius: 1rem;
				}
			}
		}
	}
`;

const AdditionalFilters = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0.75rem 0;
	color: white;
`;

const RadioGroup = styled.div`
	text-align: left;
	padding: 1rem;
`;

const Collection = styled.div`
	padding: 0 2%;
`;

const defaultState: MyState = {
	layers,
	filters: [],
	results: collectionOne,
	sortBy: 'ID',
	filterComparison: 'AND',
	modal: null,
	showModal: false,
};

class Main extends Component<{}, MyState> {
	constructor(props: any) {
		super(props);

		this.state = { ...defaultState };
	}

	showModal = (data: NFTdata) => {
		console.log('showModal called');
		this.setState({ modal: data, showModal: true });
	};

	hideModal = () => {
		console.log('hideModal called');
		this.setState({ showModal: false });
	};

	toggleComparison = (operator: string) => {
		this.setState({ filterComparison: operator });
		this.updateResults(this.state.filters, operator);
	};

	toggleSortBy = (sortBy: string) => {
		this.sortResults(this.state.results, sortBy);
	};

	/**
	 *  Sorts the result set by rarity or ID
	 */
	sortResults = (results: NFTdata[] | null, sortByUpdate?: string) => {
		// use results from state if none passed
		if (!results) results = this.state.results;

		// grab sortBy from param if passed in, else from state
		const sortBy = sortByUpdate || this.state.sortBy;

		// sort results
		const sortedResults = results.sort((a, b) => {
			return sortBy === 'rarity'
				? Number(a.overallRarity) - Number(b.overallRarity)
				: a.id - b.id;
		});

		// update state with freshly sorted and/or filtered results
		this.setState({ results: sortedResults, sortBy: sortBy });
	};

	/**
	 * Updates the result set when filters or operator changes
	 */
	updateResults = (
		filters: Property[],
		operator: string,
		filterUpdated = false
	) => {
		console.log(this.state);

		// if no filters, it's a new page load, return default
		if (!filters.length) return this.sortResults(collectionOne);

		// // update state of radio button
		// if (operator) this.setState({ filterComparison: operator });

		// grab comparison from param if passed in, else from state
		const filterComparison = operator || this.state.filterComparison;

		let dataSet: NFTdata[];
		// when a layer filter is updated with a new one, we must start search with full collection
		if (filterUpdated) dataSet = collectionOne;
		// search results get smaller with AND, use existing results
		else
			dataSet = filterComparison === 'AND' ? this.state.results : collectionOne;

		const filteredResults = dataSet.filter((nftData) => {
			// if AND, every filter must match
			if (filterComparison === 'AND') {
				return filters.every((filter) => {
					return nftData.properties.some((prop) => {
						// do %like% comparison for animal & colors
						if (filter.layer.includes('Animal') && prop.layer === 'Creature') {
							return prop.variation.includes(filter.variation);
						} else if (filter.layer === prop.layer) {
							return filter.variation === prop.variation;
						} else return false;
					});
				});
			}

			// if OR, some filters must
			if (filterComparison === 'OR') {
				return nftData.properties.some((prop) => {
					return filters.some((filter) => {
						// do %like% comparison for animal & colors
						if (filter.layer.includes('Animal') && prop.layer === 'Creature') {
							return prop.variation.includes(filter.variation);
						}
						return prop.variation === filter.variation;
					});
				});
			}
			// should never happen, just to shutup linter
			return false;
		});

		// return sorted results
		return this.sortResults(filteredResults);
	};

	onActiveFilterClick = (filter: Property) => {
		const newFilters = this.state.filters.filter((f) => {
			if (filter === f) return false;
			return true;
		});

		this.setState({ filters: newFilters });
		this.updateResults(newFilters, this.state.filterComparison, true);
	};

	/**
	 * Adds a filter or updates an existing filter in the state before updating the results
	 */
	updateFilters = (event: any) => {
		if (!event) return;

		const newFilter: Property = {
			layer: event.target.getAttribute('data-name'),
			variation: event.target.getAttribute('data-variation'),
			rarity: event.target.getAttribute('data-rarity'),
		};

		// replace existing filter for a given layer/trait_type with updated value
		let updatedValueOfAlreadyFilteredLayer = false;
		const updatedFilters = this.state.filters.map((existingFilter) => {
			if (existingFilter.layer === newFilter.layer) {
				existingFilter.variation = newFilter.variation;
				updatedValueOfAlreadyFilteredLayer = true;
			}
			return existingFilter;
		});

		const filters = updatedValueOfAlreadyFilteredLayer
			? updatedFilters
			: [...this.state.filters, newFilter];
		this.setState({ filters });
		this.updateResults(
			filters,
			this.state.filterComparison,
			updatedValueOfAlreadyFilteredLayer
		);
	};

	// @todo
	/**
	 * clear all filters button
	 * update cursor pointers
	 * revamp active filter styling, perhaps add active state to filter menu if in filter array
	 * improve mobile styling and dropdown etc.
	 */

	// @todo should probably use callback after setting state to update results instead of calling directly
	// @todo search by ID?
	// @todo add spinner, show on button clicks, hide on componentDidMount()?
	// @todo bring in bootstrap or bs components
	// @todo add chevrons to dropdowns?
	// @todo add pagination: https://blog.logrocket.com/4-ways-to-render-large-lists-in-react/?

	render() {
		return (
			<MainContent id='main-content'>
				<FilterMenu id='filter-menu'>
					<Layers>
						{layers
							.filter((layer) => layer.name !== 'Creature')
							.map((layer, i) => {
								const { name, variations } = layer;
								return (
									<LayerSelect
										key={i}
										name={name}
										variations={variations}
										updateFilters={() => this.updateFilters}
									/>
								);
							})}
					</Layers>
					<AdditionalFilters>
						<RadioGroup>
							<Toggler
								label={'Sort By'}
								values={['rarity', 'ID']}
								activeValue={this.state.sortBy}
								onClick={this.toggleSortBy}
							></Toggler>
						</RadioGroup>
						<RadioGroup>
							<Toggler
								label={'Comparison'}
								values={['AND', 'OR']}
								activeValue={this.state.filterComparison}
								onClick={this.toggleComparison}
							></Toggler>
						</RadioGroup>
					</AdditionalFilters>
					<ActiveFilters
						filters={this.state.filters}
						onClick={this.onActiveFilterClick}
						totalResults={this.state.results.length}
					/>
				</FilterMenu>
				{/* <ReefModal
					data={this.state.modal}
					showModal={this.state.showModal}
					handler={this.hideModal}
				/> */}
				<Collection id='collection'>
					{this.state.results.map((nftData) => {
						return (
							<NFT key={nftData.id} nft={nftData} onClick={this.showModal} />
						);
					})}
				</Collection>
			</MainContent>
		);
	}
}

export default Main;
