export const layers = [
	{
		name: 'Background',
		variations: [
			{ name: 'Bay Anchovies', rarity: '9' },
			{ name: 'Blueback Herring', rarity: '9' },
			{ name: 'Lookdown', rarity: '9' },
			{ name: 'Silver Jenny Mojarra', rarity: '9' },
			{ name: 'Schoolmaster Snapper', rarity: '9' },
			{ name: 'Pufferfish', rarity: '7' },
			{ name: 'Rainbow Runner', rarity: '7' },
			{ name: 'Great Barracuda', rarity: '7' },
			{ name: 'Red Snapper', rarity: '7' },
			{ name: 'Yellowfin Tuna', rarity: '5' },
			{ name: 'Atlantic Stingray', rarity: '5' },
			{ name: 'Squid', rarity: '5' },
			{ name: 'None', rarity: '4' },
			{ name: 'Wahoo', rarity: '3' },
			{ name: 'Anglerfish', rarity: '3' },
			{ name: 'Sea Turtles', rarity: '2' },
		],
	},
	{
		name: 'Coral',
		variations: [
			{ name: 'Brain', rarity: '8' },
			{ name: 'Elkhorn', rarity: '8' },
			{ name: 'Acropora', rarity: '8' },
			{ name: 'Anemone', rarity: '8' },
			{ name: 'Table Top', rarity: '8' },
			{ name: 'Lifeless', rarity: '7' },
			{ name: 'Mushroom Leather', rarity: '7' },
			{ name: 'Dendrophyllia', rarity: '7' },
			{ name: 'Tube Coral', rarity: '7' },
			{ name: 'Tube Worm', rarity: '7' },
			{ name: 'None', rarity: '5' },
			{ name: 'Indo Gold', rarity: '5' },
			{ name: 'Red Whip', rarity: '5' },
			{ name: 'Sponge Coral', rarity: '5' },
			{ name: 'Kenya Tree', rarity: '3' },
			{ name: 'Gold Tip', rarity: '2' },
		],
	},
	{
		name: 'Sand',
		variations: [
			{ name: 'Rocky', rarity: '15' },
			{ name: 'Purple', rarity: '11' },
			{ name: 'Red', rarity: '11' },
			{ name: 'White', rarity: '11' },
			{ name: 'Green', rarity: '9' },
			{ name: 'Pink', rarity: '9' },
			{ name: 'Orange', rarity: '9' },
			{ name: 'Shell', rarity: '7' },
			{ name: 'Glass', rarity: '7' },
			{ name: 'Black', rarity: '7' },
			{ name: 'Bioluminescent', rarity: '4' },
		],
	},
	{
		name: 'Time',
		variations: [
			{ name: 'Sunrise', rarity: '19' },
			{ name: 'Afternoon', rarity: '19' },
			{ name: 'Midday', rarity: '19' },
			{ name: 'Morning', rarity: '19' },
			{ name: 'Night', rarity: '19' },
			{ name: 'None', rarity: '5' },
		],
	},
	{
		name: 'Shell',
		variations: [
			{ name: 'Strawberry', rarity: '12' },
			{ name: 'Sand Dollar', rarity: '12' },
			{ name: 'Starfish', rarity: '10' },
			{ name: 'Cockle', rarity: '10' },
			{ name: 'Turban', rarity: '10' },
			{ name: 'Conus Gloriamaris', rarity: '7' },
			{ name: 'Nautilus', rarity: '7' },
			{ name: 'None', rarity: '7' },
			{ name: 'Miyoko Murex', rarity: '7' },
			{ name: 'Queen Conch', rarity: '5' },
			{ name: 'New Zealand Abalone', rarity: '5' },
			{ name: 'Venus Comb Murex', rarity: '5' },
			{ name: 'Sphaerocypraea Incomparabilis', rarity: '3' },
		],
	},
	{
		name: 'Vegetation',
		variations: [
			{ name: 'Kelp', rarity: '9.20' },
			{ name: 'Seagrass', rarity: '9.20' },
			{ name: 'Seaweed', rarity: '9.20' },
			{ name: 'Pink Algae', rarity: '9.20' },
			{ name: 'Cryptotethya Crypta', rarity: '9.2' },
			{ name: 'Sea Plume', rarity: '7' },
			{ name: 'Posidonia', rarity: '7' },
			{ name: 'Sea Worm', rarity: '7' },
			{ name: 'Feather Star', rarity: '7' },
			{ name: 'Acetabularia Acetabulum', rarity: '5' },
			{ name: 'Red Algae', rarity: '5' },
			{ name: 'Red Whip', rarity: '5' },
			{ name: 'None', rarity: '3' },
			{ name: 'Neptune Grass', rarity: '3' },
			{ name: 'Plume De Mer', rarity: '3' },
			{ name: 'Blue Mushroom', rarity: '2' },
		],
	},
	{
		name: 'Water',
		variations: [
			{ name: 'Crystal Clear', rarity: '9' },
			{ name: 'Hot Aquamarine', rarity: '9' },
			{ name: 'Deep Aquamarine', rarity: '9' },
			{ name: 'Hot Purple', rarity: '9' },
			{ name: 'Cyan Ray', rarity: '8' },
			{ name: 'Pink Fleur', rarity: '7' },
			{ name: 'Daydream', rarity: '7' },
			{ name: 'Grenwick', rarity: '7' },
			{ name: 'Swamp', rarity: '6' },
			{ name: 'Rose Beam', rarity: '6' },
			{ name: 'Odyssey', rarity: '6' },
			{ name: 'Bloody Ray', rarity: '5' },
			{ name: 'Gold Ray', rarity: '5' },
			{ name: 'Oil Spill', rarity: '4' },
			{ name: 'Rainbow', rarity: '3' },
		],
	},
	{
		name: 'Creature',
		variations: [
			{ name: 'Seahorse Grey', rarity: 1.5 },
			{ name: 'Seahorse Blue', rarity: 1.5 },
			{ name: 'Seahorse Green', rarity: 1.3 },
			{ name: 'Seahorse Orange', rarity: 0.9 },
			{ name: 'Seahorse Pink', rarity: 0.9 },
			{ name: 'Seahorse Red', rarity: 0.9 },
			{ name: 'Seahorse Purple', rarity: 0.7 },
			{ name: 'Seahorse Black', rarity: 0.7 },
			{ name: 'Seahorse Rainbow', rarity: 0.6 },
			{ name: 'Seahorse Golden', rarity: 0.6 },
			{ name: 'Seahorse Cosmic', rarity: 0.4 },
			{ name: 'Jellyfish Grey', rarity: 1.5 },
			{ name: 'Jellyfish Blue', rarity: 1.5 },
			{ name: 'Jellyfish Green', rarity: 1.3 },
			{ name: 'Jellyfish Orange', rarity: 0.9 },
			{ name: 'Jellyfish Pink', rarity: 0.9 },
			{ name: 'Jellyfish Red', rarity: 0.9 },
			{ name: 'Jellyfish Purple', rarity: 0.7 },
			{ name: 'Jellyfish Black', rarity: 0.7 },
			{ name: 'Jellyfish Rainbow', rarity: 0.6 },
			{ name: 'Jellyfish Golden', rarity: 0.6 },
			{ name: 'Jellyfish Cosmic', rarity: 0.4 },
			{ name: 'Octopus Grey', rarity: 1.5 },
			{ name: 'Octopus Blue', rarity: 1.5 },
			{ name: 'Octopus Green', rarity: 1.3 },
			{ name: 'Octopus Orange', rarity: 0.9 },
			{ name: 'Octopus Pink', rarity: 0.9 },
			{ name: 'Octopus Red', rarity: 0.9 },
			{ name: 'Octopus Purple', rarity: 0.7 },
			{ name: 'Octopus Black', rarity: 0.7 },
			{ name: 'Octopus Rainbow', rarity: 0.6 },
			{ name: 'Octopus Golden', rarity: 0.6 },
			{ name: 'Octopus Cosmic', rarity: 0.4 },
			{ name: 'Sunfish Grey', rarity: 1.2 },
			{ name: 'Sunfish Blue', rarity: 1.2 },
			{ name: 'Sunfish Green', rarity: 1.04 },
			{ name: 'Sunfish Orange', rarity: 0.72 },
			{ name: 'Sunfish Pink', rarity: 0.72 },
			{ name: 'Sunfish Red', rarity: 0.72 },
			{ name: 'Sunfish Purple', rarity: 0.56 },
			{ name: 'Sunfish Black', rarity: 0.56 },
			{ name: 'Sunfish Rainbow', rarity: 0.48 },
			{ name: 'Sunfish Golden', rarity: 0.48 },
			{ name: 'Sunfish Cosmic', rarity: 0.32 },
			{ name: 'Manta Ray Grey', rarity: 1.2 },
			{ name: 'Manta Ray Blue', rarity: 1.2 },
			{ name: 'Manta Ray Green', rarity: 1.04 },
			{ name: 'Manta Ray Orange', rarity: 0.72 },
			{ name: 'Manta Ray Pink', rarity: 0.72 },
			{ name: 'Manta Ray Red', rarity: 0.72 },
			{ name: 'Manta Ray Purple', rarity: 0.56 },
			{ name: 'Manta Ray Black', rarity: 0.56 },
			{ name: 'Manta Ray Rainbow', rarity: 0.48 },
			{ name: 'Manta Ray Golden', rarity: 0.48 },
			{ name: 'Manta Ray Cosmic', rarity: 0.32 },
			{ name: 'Swordfish Grey', rarity: 1.2 },
			{ name: 'Swordfish Blue', rarity: 1.2 },
			{ name: 'Swordfish Green', rarity: 1.04 },
			{ name: 'Swordfish Orange', rarity: 0.72 },
			{ name: 'Swordfish Pink', rarity: 0.72 },
			{ name: 'Swordfish Red', rarity: 0.72 },
			{ name: 'Swordfish Purple', rarity: 0.56 },
			{ name: 'Swordfish Black', rarity: 0.56 },
			{ name: 'Swordfish Rainbow', rarity: 0.48 },
			{ name: 'Swordfish Golden', rarity: 0.48 },
			{ name: 'Swordfish Cosmic', rarity: 0.32 },
			{ name: 'Sea Turtle Grey', rarity: 1.05 },
			{ name: 'Sea Turtle Blue', rarity: 1.05 },
			{ name: 'Sea Turtle Green', rarity: 0.91 },
			{ name: 'Sea Turtle Orange', rarity: 0.63 },
			{ name: 'Sea Turtle Pink', rarity: 0.63 },
			{ name: 'Sea Turtle Red', rarity: 0.63 },
			{ name: 'Sea Turtle Purple', rarity: 0.49 },
			{ name: 'Sea Turtle Black', rarity: 0.49 },
			{ name: 'Sea Turtle Rainbow', rarity: 0.42 },
			{ name: 'Sea Turtle Golden', rarity: 0.42 },
			{ name: 'Sea Turtle Cosmic', rarity: 0.28 },
			{ name: 'Mahi Mahi Grey', rarity: 1.05 },
			{ name: 'Mahi Mahi Blue', rarity: 1.05 },
			{ name: 'Mahi Mahi Green', rarity: 0.91 },
			{ name: 'Mahi Mahi Orange', rarity: 0.63 },
			{ name: 'Mahi Mahi Pink', rarity: 0.63 },
			{ name: 'Mahi Mahi Red', rarity: 0.63 },
			{ name: 'Mahi Mahi Purple', rarity: 0.49 },
			{ name: 'Mahi Mahi Black', rarity: 0.49 },
			{ name: 'Mahi Mahi Rainbow', rarity: 0.42 },
			{ name: 'Mahi Mahi Golden', rarity: 0.42 },
			{ name: 'Mahi Mahi Cosmic', rarity: 0.28 },
			{ name: 'Narwhal Grey', rarity: 1.05 },
			{ name: 'Narwhal Blue', rarity: 1.05 },
			{ name: 'Narwhal Green', rarity: 0.91 },
			{ name: 'Narwhal Orange', rarity: 0.63 },
			{ name: 'Narwhal Pink', rarity: 0.63 },
			{ name: 'Narwhal Red', rarity: 0.63 },
			{ name: 'Narwhal Purple', rarity: 0.49 },
			{ name: 'Narwhal Black', rarity: 0.49 },
			{ name: 'Narwhal Rainbow', rarity: 0.42 },
			{ name: 'Narwhal Golden', rarity: 0.42 },
			{ name: 'Narwhal Cosmic', rarity: 0.28 },
			{ name: 'Hammerhead Grey', rarity: 0.75 },
			{ name: 'Hammerhead Blue', rarity: 0.75 },
			{ name: 'Hammerhead Green', rarity: 0.65 },
			{ name: 'Hammerhead Orange', rarity: 0.45 },
			{ name: 'Hammerhead Pink', rarity: 0.45 },
			{ name: 'Hammerhead Red', rarity: 0.45 },
			{ name: 'Hammerhead Purple', rarity: 0.35 },
			{ name: 'Hammerhead Black', rarity: 0.35 },
			{ name: 'Hammerhead Rainbow', rarity: 0.3 },
			{ name: 'Hammerhead Golden', rarity: 0.3 },
			{ name: 'Hammerhead Cosmic', rarity: 0.2 },
			{ name: 'Dolphin Grey', rarity: 0.75 },
			{ name: 'Dolphin Blue', rarity: 0.75 },
			{ name: 'Dolphin Green', rarity: 0.65 },
			{ name: 'Dolphin Orange', rarity: 0.45 },
			{ name: 'Dolphin Pink', rarity: 0.45 },
			{ name: 'Dolphin Red', rarity: 0.45 },
			{ name: 'Dolphin Purple', rarity: 0.35 },
			{ name: 'Dolphin Black', rarity: 0.35 },
			{ name: 'Dolphin Rainbow', rarity: 0.3 },
			{ name: 'Dolphin Golden', rarity: 0.3 },
			{ name: 'Dolphin Cosmic', rarity: 0.2 },
			{ name: 'Orca Grey', rarity: 0.6 },
			{ name: 'Orca Blue', rarity: 0.6 },
			{ name: 'Orca Green', rarity: 0.52 },
			{ name: 'Orca Orange', rarity: 0.36 },
			{ name: 'Orca Pink', rarity: 0.36 },
			{ name: 'Orca Red', rarity: 0.36 },
			{ name: 'Orca Purple', rarity: 0.28 },
			{ name: 'Orca Black', rarity: 0.28 },
			{ name: 'Orca Rainbow', rarity: 0.24 },
			{ name: 'Orca Golden', rarity: 0.24 },
			{ name: 'Orca Cosmic', rarity: 0.16 },
			{ name: 'Blue Whale Grey', rarity: 0.6 },
			{ name: 'Blue Whale Blue', rarity: 0.6 },
			{ name: 'Blue Whale Green', rarity: 0.52 },
			{ name: 'Blue Whale Orange', rarity: 0.36 },
			{ name: 'Blue Whale Pink', rarity: 0.36 },
			{ name: 'Blue Whale Red', rarity: 0.36 },
			{ name: 'Blue Whale Purple', rarity: 0.28 },
			{ name: 'Blue Whale Black', rarity: 0.28 },
			{ name: 'Blue Whale Rainbow', rarity: 0.24 },
			{ name: 'Blue Whale Golden', rarity: 0.24 },
			{ name: 'Blue Whale Cosmic', rarity: 0.16 },
			{ name: 'Ribbon Eel Grey', rarity: 0.6 },
			{ name: 'Ribbon Eel Blue', rarity: 0.6 },
			{ name: 'Ribbon Eel Green', rarity: 0.52 },
			{ name: 'Ribbon Eel Orange', rarity: 0.36 },
			{ name: 'Ribbon Eel Pink', rarity: 0.36 },
			{ name: 'Ribbon Eel Red', rarity: 0.36 },
			{ name: 'Ribbon Eel Purple', rarity: 0.28 },
			{ name: 'Ribbon Eel Black', rarity: 0.28 },
			{ name: 'Ribbon Eel Rainbow', rarity: 0.24 },
			{ name: 'Ribbon Eel Golden', rarity: 0.24 },
			{ name: 'Ribbon Eel Cosmic', rarity: 0.16 },
			{ name: 'Axolotl Grey', rarity: 0.45 },
			{ name: 'Axolotl Blue', rarity: 0.45 },
			{ name: 'Axolotl Green', rarity: 0.39 },
			{ name: 'Axolotl Orange', rarity: 0.27 },
			{ name: 'Axolotl Pink', rarity: 0.27 },
			{ name: 'Axolotl Red', rarity: 0.27 },
			{ name: 'Axolotl Purple', rarity: 0.21 },
			{ name: 'Axolotl Black', rarity: 0.21 },
			{ name: 'Axolotl Rainbow', rarity: 0.18 },
			{ name: 'Axolotl Golden', rarity: 0.18 },
			{ name: 'Axolotl Cosmic', rarity: 0.12 },
		],
	},
	{
		name: 'Animal',
		variations: [
			{ name: 'Seahorse', rarity: '10' },
			{ name: 'Jellyfish', rarity: '10' },
			{ name: 'Octopus', rarity: '10' },
			{ name: 'Sunfish', rarity: ' 8' },
			{ name: 'Manta Ray', rarity: ' 8' },
			{ name: 'Swordfish', rarity: ' 8' },
			{ name: 'Sea Turtle', rarity: ' 7' },
			{ name: 'Mahi Mahi', rarity: ' 7' },
			{ name: 'Narwhal', rarity: ' 7' },
			{ name: 'Hammerhead', rarity: ' 5' },
			{ name: 'Dolphin', rarity: ' 5' },
			{ name: 'Orca', rarity: '4' },
			{ name: 'Blue Whale', rarity: '4' },
			{ name: 'Ribbon Eel', rarity: '4' },
			{ name: 'Axolotl', rarity: '3' },
		],
	},
	{
		name: 'Animal Color',
		variations: [
			{ name: 'Grey', rarity: '15' },
			{ name: 'Blue', rarity: '15' },
			{ name: 'Green', rarity: '13' },
			{ name: 'Orange', rarity: '9' },
			{ name: 'Pink', rarity: '9' },
			{ name: 'Red', rarity: '9' },
			{ name: 'Purple', rarity: '7' },
			{ name: 'Black', rarity: '7' },
			{ name: 'Rainbow', rarity: '6' },
			{ name: 'Golden', rarity: '6' },
			{ name: 'Cosmic', rarity: '4' },
		],
	},
];
