import * as React from 'react';
import styled from 'styled-components';

interface nftData {
	id: number;
	image: string;
	properties: { layer: string; variation: string; rarity: number | string }[];
	overallRarity: number | string;
}
interface NFTProps {
	nft: nftData;
	onClick: Function;
}

const NFTWrapper = styled.div`
	display: inline-block;
	margin: 1rem;
	border-radius: 1rem;
	font-size: 0.85rem;
	margin: 0.75rem 0.75rem;
	background: linear-gradient(45deg, rgb(60, 60, 60), black);
	color: white;
	box-shadow: 3px 3px 3px #000;

	div {
		padding: 0.25rem;
	}
`;

const Image = styled.img`
	height: 250px;
	width: 250px;

	@media only screen and (max-width: 479px) {
		font-size: 50%;
	}
`;

const NFT = (props: NFTProps) => {
	const { nft, onClick } = props;
	return (
		<NFTWrapper data-rarity={nft.overallRarity} onClick={() => onClick(nft)}>
			<Image src={nft.image} alt={`Collection 1: ${nft.id}/1500`} />
			<div>{`#${nft.id}`}</div>
		</NFTWrapper>
	);
};

export default NFT;
