import * as React from 'react';
import styled from 'styled-components';

interface Variation {
	name: string;
	rarity: number | string;
}
interface Layers {
	name: string;
	variations: Variation[];
	updateFilters: any;
}

const Dropdown = styled.div`
	position: relative;
	display: inline-block;

	:hover {
		.dropdown-content {
			display: block;
		}
		.dropdown {
			background: linear-gradient(45deg, rgb(0, 0, 255), black);
		}
	}

	.dropdown {
		background-color: rgb(0, 0, 0);
		color: white;
		padding: 0.75rem 1rem;
		margin: 0 0.1rem;
		font-size: 1.05rem;
		transition: ease-in 0.2s;
		margin-bottom: 2px;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		z-index: 1;
		background-color: rgb(68, 68, 68);
		filter: brightness(125%);
		min-width: 14px;
		box-shadow: 2.5px 3px 3px #000;
		border-radius: 0.5rem;
		line-height: 1.2;

		span {
			padding: 0.75rem 1rem;
			display: block;
			color: white;
			text-align: left;

			:hover {
				background: linear-gradient(45deg, rgb(23, 0, 255), black);
				&:first-child {
					border-top-left-radius: 0.5rem;
					border-top-right-radius: 0.5rem;
				}
				&last-child {
					border-bottom-left-radius: 0.5rem;
					border-bottom-right-radius: 0.5rem;
				}
			}
		}
	}
`;
const LayerSelect = ({ name, variations, updateFilters }: Layers) => {
	return (
		<Dropdown className='dropdown'>
			<div className='dropdown'>{name}</div>
			<div className='dropdown-content'>
				{variations.map((variation, i) => {
					return (
						<span
							key={i}
							data-name={name}
							data-variation={variation.name}
							data-rarity={variation.rarity}
							onClick={updateFilters()}
						>
							{variation.name}
						</span>
					);
				})}
			</div>
		</Dropdown>
	);
};

export default LayerSelect;
