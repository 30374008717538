import React from 'react';
import Main from './components/Main';
import Header from './components/Header';
import styled from 'styled-components';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import 'font-awesome/css/font-awesome.min.css';

const appInsights = new ApplicationInsights({
	config: {
		connectionString:
			'InstrumentationKey=e2e2e8c3-3a3b-4d61-bec4-5eb8725eaaee;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/',
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

const Container = styled.div`
	text-align: center;
	//background:  rgb(68,68,68)
`;

function App() {
	return (
		<Container>
			<Header />
			<Main />
			<footer></footer>
		</Container>
	);
}

export default App;
